import React, { Component } from 'react';
import {
  Switch,
  BrowserRouter as Router,
  Route,
  Redirect
} from 'react-router-dom';
import decode from 'jwt-decode';
import Login from '../Login/Login';
import SuperUserLogin from '../Login/SuperUserLogin';
import ValidateSignup from '../Login/ValidateSignup';
import ForgotPassword from '../Login/ForgotPassword';
import ResetPassword from '../Login/ResetPassword';
import ResetOnPrimiseUserPassword from '../Login/resetOnPrimisePassword';
import Home from '../Home';
import Application from '../Application';
import _ from 'lodash';

class AuthService extends Component {
  // Initializing important variables
  constructor(domain) {
    super();

    this.domain =
      domain || process.env.REACT_APP_SERVER_URL || 'http://localhost:3000';
    this.superuserdomain =
      process.env.REACT_APP_SUPER_USER_URL || 'http://localhost:5000';
    this.fetch = this.fetch.bind(this); // React binding stuff
    this.login = this.login.bind(this);
    this.getProfile = this.getProfile.bind(this);
    this.register = this.register.bind(this);
    this.logout = this.logout.bind(this);
    // Lines related functions // TODO: Move this to different file
    this.lines = this.lines.bind(this);
  }

  // Login function
  login(email, password) {
    // Get a token from api server using the fetch api.
    let details = {
      // ToDO : remove all this clutter using utilities.
      email: email,
      password: password
    };

    return this.fetch(`${this.domain}/auth/login`, {
      method: 'POST',
      body: JSON.stringify(details)
    }).then(res => {
      if (res.success) {
        // Setting the token in localStorage
        this.setToken(res.token);
      }
      return Promise.resolve(res);
    });
  }

  // Rules and privileges.
  site() {
    return this.fetch(`${this.domain}/api/site`, {
      method: 'GET'
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Rules and privileges.
  rules() {
    return this.fetch(`${this.domain}/api/getuserprivileges`, {
      method: 'GET'
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Super user Login function
  superuserlogin(email, password) {
    // Get a token from api server using the fetch api.
    let details = {
      // ToDO : remove all this clutter using utilities.
      email: email,
      password: password
    };

    return this.fetch(`${this.domain}/auth/superuserlogin`, {
      method: 'POST',
      body: JSON.stringify(details)
    }).then(res => {
      if (res.success) {
        this.setToken(res.token); // Setting the token in localStorage
      }
      return Promise.resolve(res);
    });
  }

  // Check if super user loggin in.
  checkSuperUserLoginRequired() {
    return this.fetch(`${this.domain}/auth/checksuperlogin`, {
      method: 'GET'
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Register function
  register(username, password, token, email) {
    // Get a token from api server using the fetch api.
    let details = {
      username: username,
      password: password,
      token: token,
      email: email
    };

    return this.fetch(`${this.domain}/auth/signup`, {
      method: 'POST',
      body: JSON.stringify(details)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Verify sign up
  verifySignup(token) {
    return this.fetch(`${this.domain}/auth/validatesignup/` + token, {
      method: 'GET'
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Forgot password
  forgotPassword(email) {
    let details = {
      email: email
    };

    return this.fetch(`${this.domain}/auth/forgotpassword`, {
      method: 'POST',
      body: JSON.stringify(details)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Verify reset password
  verifyResetPassword(token) {
    return this.fetch(`${this.domain}/auth/resetpassword/` + token, {
      method: 'GET'
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Reset Password Function.
  resetPassword(token, password, confirmPassword) {
    let details = {
      resetPasswordToken: token,
      password: password,
      confirmPassword: confirmPassword
    };

    return this.fetch(`${this.domain}/auth/resetpassword`, {
      method: 'POST',
      body: JSON.stringify(details)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  //FAQ's

  /**
   * Adds a New FAQ
   * @param {*} payload
   */
  addNewFaq(payload) {
    return this.fetch(`${this.domain}/api/questionAndAnswer`, {
      method: 'POST',
      body: JSON.stringify(payload)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  /**
   *Fetches all FAQ's
   */

  getAllFaqs(active) {
    return this.fetch(
      `${this.domain}/api/questionAndAnswers` +
        `${active ? '?active=true' : ''}`,
      {
        method: 'GET'
      }
    ).then(res => {
      return Promise.resolve(res);
    });
  }

  /**
   *Updates FAQ
   */

  updateFaq(id, payload) {
    return this.fetch(
      `${this.domain}/api/questionAndAnswers` + `${id ? '/' + id : ''}`,
      {
        method: 'POST',
        body: JSON.stringify(payload)
      }
    ).then(res => {
      return Promise.resolve(res);
    });
  }

  /**
   *Fetch an FAQ
   */

  getFaq(id) {
    return this.fetch(
      `${this.domain}/api/questionAndAnswers` + `${id ? '/' + id : ''}`,
      {
        method: 'GET'
      }
    ).then(res => {
      return Promise.resolve(res);
    });
  }

  /**
   *Deletes an FAQ
   */

  deleteFaq(id) {
    return this.fetch(
      `${this.domain}/api/questionAndAnswers` + `${id ? '/' + id : ''}`,
      {
        method: 'DELETE'
      }
    ).then(res => {
      return Promise.resolve(res);
    });
  }

  // get all Lines
  // TODO: Move to to different files.
  lines(params) {
    if (params) {
      return this.fetch(`${this.domain}/api/lines`, {
        method: 'POST',
        body: JSON.stringify(params)
      }).then(res => {
        return Promise.resolve(res);
      });
    } else {
      return this.fetch(`${this.domain}/api/lines`, {
        method: 'GET'
      }).then(res => {
        return Promise.resolve(res);
      });
    }
  }

  // get all stop time groups
  stopTimeGroups(params) {
    return this.fetch(`${this.domain}/api/stoptimereasongroup`, {
      method: 'GET'
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  //Stop Time Reasons

  getStopTimeReasons(lineId) {
    return this.fetch(
      `${this.domain}/api/stopreasons` + `${lineId ? `?lineId=` + lineId : ''}`,
      {
        method: 'GET'
      }
    ).then(res => {
      return Promise.resolve(res);
    });
  }

  getStopTimeReason(reasonId) {
    return this.fetch(
      `${this.domain}/api/stopreasons` + `${reasonId ? '/' + reasonId : ''}`,
      {
        method: 'GET'
      }
    ).then(res => {
      return Promise.resolve(res);
    });
  }

  newStopTimeReason(params) {
    return this.fetch(`${this.domain}/api/stopreason`, {
      method: 'POST',
      body: JSON.stringify(params)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  editStopTimeReason(data, reasonId) {
    return this.fetch(
      `${this.domain}/api/stopreasons` + `${reasonId ? '/' + reasonId : ''}`,
      {
        method: 'POST',
        body: JSON.stringify(data)
      }
    ).then(res => {
      return Promise.resolve(res);
    });
  }

  deleteStopTimeReason(reasonId) {
    return this.fetch(
      `${this.domain}/api/stopreasons` + `${reasonId ? '/' + reasonId : ''}`,
      {
        method: 'DELETE'
      }
    ).then(res => {
      return Promise.resolve(res);
    });
  }

  // Download Pdf for stop time reason.
  downloadPdfForStopTimeReport(filters, data) {
    let url = `${this.domain}/api/stopreasons/generatePdf?${
      filters.lineName === 'ALL' ? '' : `lineName=${filters.lineName}`
    }&fromDateTime=${filters.fromDateTime}&toDateTime=${filters.toDateTime}&${
      filters.searchKey ? `searchKey=${filters.searchKey}` : ''
    }`;

    let body = {
      ...(data.headers ? { headers: data.headers } : {}),
      ...(data.heading ? { heading: data.heading } : {}),
      ...(data.bodyData ? { data: data.bodyData } : {}),
      ...(data.matchKeys ? { matchKeys: data.matchKeys } : {}),
      ...(data.images ? { images: data.images } : {}),
      ...(data.isHsBar ? { isHsBar: true } : {})
    };

    let options = {
      method: 'POST',
      body: JSON.stringify(body)
    };

    return this.fetchNonJson(url, options)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  //get all alarms config

  getAllAlarmsConfig(params) {
    return this.fetch(`${this.domain}/api/alarms/config`, {
      method: 'GET'
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // get Dashboard Data

  getDashboardTableData(params) {
    return this.fetch(`${this.domain}/api/dashboard/reportdashboarddetails`, {
      method: 'POST',
      body: JSON.stringify(params)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  reportdashboardbyshifts(params) {
    return this.fetch(`${this.domain}/api/dashboard/reportdashboardbyshifts`, {
      method: 'POST',
      body: JSON.stringify(params)
    }).then(res => {
      return Promise.resolve(res);
    });
  }
  getDashboardProduction(params) {
    return this.fetch(`${this.domain}/api/dashboard/dashboardproduction`, {
      method: 'POST',
      body: JSON.stringify(params)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  getDashboardLineStatus(params) {
    return this.fetch(`${this.domain}/api/dashboard/linestatus`, {
      method: 'POST',
      body: JSON.stringify(params)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  getDashboardlineuptimeDowntime(params) {
    return this.fetch(`${this.domain}/api/dashboard/lineuptimedowntime`, {
      method: 'POST',
      body: JSON.stringify(params)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  getShiftWiseUtilization(params) {
    return this.fetch(`${this.domain}/api/dashboard/shiftwiseutilization`, {
      method: 'POST',
      body: JSON.stringify(params)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Add New shift
  AddShift(params) {
    return this.fetch(`${this.domain}/api/shifts/add`, {
      method: 'POST',
      body: JSON.stringify(params)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Edit shift
  EditShift(params, id) {
    return this.fetch(`${this.domain}/api/shifts/update/` + id, {
      method: 'PUT',
      body: JSON.stringify(params)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // get list of all shifts
  ViewShift() {
    return this.fetch(`${this.domain}/api/shifts`, {
      method: 'GET'
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // get shifts based on line
  GetShiftByLineId(lineId) {
    return this.fetch(`${this.domain}/api/shifts?lineId=${lineId}`, {
      method: 'GET'
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  //  shift Report
  shiftReport(lineIds, shiftId, fromDateTime, toDateTime) {
    return this.fetch(
      `${
        this.domain
      }/customreports/shift?lineIds=${lineIds}&shiftId=${shiftId}&fromDateTime=${fromDateTime}&toDateTime=${toDateTime}`,
      {
        method: 'GET'
      }
    ).then(res => {
      return Promise.resolve(res);
    });
  }
  // shift wise report
  shiftWiseReport(lineIds, shiftId, fromDateTime, toDateTime) {
    return this.fetch(
      `${
        this.domain
      }/customreports/shiftwise?lineIds=${lineIds}&shiftId=${shiftId}&fromDateTime=${fromDateTime}&toDateTime=${toDateTime}`,
      {
        method: 'GET'
      }
    ).then(res => {
      return Promise.resolve(res);
    });
  }

  // Recipe report
  RecipeReport(lineIds, recipeId, fromDateTime, toDateTime) {
    return this.fetch(
      `${
        this.domain
      }/customreports/recipecode?lineIds=${lineIds}&recipeId=${recipeId}&fromDateTime=${fromDateTime}&toDateTime=${toDateTime}`,
      {
        method: 'GET'
      }
    ).then(res => {
      return Promise.resolve(res);
    });
  }

  // lot Report
  LotReport(lineIds, recipeId, startDate, endDate) {
    return this.fetch(
      `${
        this.domain
      }/customreports/lotreport?lineIds=${lineIds}&recipeId=${recipeId}&startDate=${startDate}&endDate=${endDate}`,
      {
        method: 'GET'
      }
    ).then(res => {
      return Promise.resolve(res);
    });
  }

  // download pdf for lot report
  downloadPdfForLotReport(filters, data) {
    let url = `${this.domain}/customreports/lotreport/generatePdf?lineNames=${
      filters.lineNames
    }
    &recipeName
    =${filters.recipeName}&fromDateTime=${filters.fromDateTime}&toDateTime=${
      filters.toDateTime
    }`;

    let body = {
      ...(data.headers ? { headers: data.headers } : {}),
      ...(data.heading ? { heading: data.heading } : {}),
      ...(data.bodyData ? { data: data.bodyData } : {}),
      ...(data.matchKeys ? { matchKeys: data.matchKeys } : {}),
      ...(data.images ? { images: data.images } : {}),
      ...(data.embeddedHeaders ? { embeddedHeaders: data.embeddedHeaders } : {})
    };

    let options = {
      method: 'POST',
      body: JSON.stringify(body)
    };

    return this.fetchNonJson(url, options)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  // mixerKw report
  MixerKWReport(lineIds, recipeId, fromDateTime, toDateTime) {
    return this.fetch(
      `${
        this.domain
      }/customreports/mixerkwreport?lineIds=${lineIds}&recipeId=${recipeId}&fromDateTime=${fromDateTime}&toDateTime=${toDateTime}`,
      {
        method: 'GET'
      }
    ).then(res => {
      return Promise.resolve(res);
    });
  }

  // get Batch Report
  BatchReport(batchId) {
    return this.fetch(
      `${this.domain}/customreports/batchreport?batchId=${batchId}`,
      {
        method: 'GET'
      }
    ).then(res => {
      return Promise.resolve(res);
    });
  }

  // get recipe
  getAllReleasedRecipes(lineIds, fromDateTime, toDateTime) {
    let url = lineIds
      ? `${
          this.domain
        }/api/recipes/getallreleasedrecipes?lineIds=${lineIds}&fromDateTime=${fromDateTime}&toDateTime=${toDateTime}`
      : `${
          this.domain
        }/api/recipes/getallreleasedrecipes?&fromDateTime=${fromDateTime}&toDateTime=${toDateTime}`;

    return this.fetch(url, {
      method: 'GET'
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  getAllMaterialCodesFromRecipes(lineIds, fromDateTime, toDateTime) {
    let url = lineIds
      ? `${
          this.domain
        }/api/recipes/getallmaterialcodesfromrecipes?lineIds=${lineIds}&fromDateTime=${fromDateTime}&toDateTime=${toDateTime}`
      : `${
          this.domain
        }/api/recipes/getallmaterialcodesfromrecipes?&fromDateTime=${fromDateTime}&toDateTime=${toDateTime}`;

    return this.fetch(url, {
      method: 'GET'
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  materialProductionReport(
    lineIds,
    recipeId,
    fromDateTime,
    toDateTime,
    selectedMaterial
  ) {
    let url;
    if (lineIds && recipeId) {
      url = `${
        this.domain
      }/customreports/materialproduction?lineIds=${lineIds}&recipeId=${recipeId}&fromDateTime=${fromDateTime}&toDateTime=${toDateTime}`;
    } else {
      if (lineIds) {
        url = `${
          this.domain
        }/customreports/materialproduction?lineIds=${lineIds}&fromDateTime=${fromDateTime}&toDateTime=${toDateTime}`;
      } else {
        url = `${
          this.domain
        }/customreports/materialproduction?&recipeId=${recipeId}&fromDateTime=${fromDateTime}&toDateTime=${toDateTime}`;
      }
    }

    if (selectedMaterial) {
      url = url + `&selectedMaterial=${selectedMaterial}`;
    }

    return this.fetch(url, {
      method: 'GET'
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // material consumption report
  materialConsumptionReport(
    lineIds,
    fromDateTime,
    toDateTime,
    materialCategoryId = null,
    materialId = null
  ) {
    let url = `${
      this.domain
    }/customreports/materialconsumption?lineIds=${lineIds}&fromDateTime=${fromDateTime}&toDateTime=${toDateTime}`;
    if (materialCategoryId && !materialId) {
      url = url + `&materialCategoryId=${materialCategoryId}`;
    } else if (materialId) {
      url = url + `&materialId=${materialId}`;
    }
    return this.fetch(url, {
      method: 'GET'
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Download Pdf for Recipe Report.
  downloadPdfForRecipeReport(filters, data) {
    let url = `${
      this.domain
    }/customreports/recipecodewise/generatePdf?lineNames=${filters.lineNames}
  &recipeName
  =${filters.recipeName}&fromDateTime=${filters.fromDateTime}&toDateTime=${
      filters.toDateTime
    }`;

    let body = {
      ...(data.headers ? { headers: data.headers } : {}),
      ...(data.heading ? { heading: data.heading } : {}),
      ...(data.bodyData ? { data: data.bodyData } : {}),
      ...(data.matchKeys ? { matchKeys: data.matchKeys } : {}),
      ...(data.images ? { images: data.images } : {})
    };

    let options = {
      method: 'POST',
      body: JSON.stringify(body)
    };

    return this.fetchNonJson(url, options)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  // Download Pdf for Shift Report.
  downloadPdfForShiftReport(filters, data) {
    let url = `${this.domain}/customreports/shift/generatePdf?lineNames=${
      filters.lineNames
    }
  &shiftName=${filters.shiftName}&fromDateTime=${
      filters.fromDateTime
    }&toDateTime=${filters.toDateTime}&shiftStartTime=${
      filters.shiftStartTime
    }&shiftEndTime=${filters.shiftEndTime}`;

    let body = {
      ...(data.headers ? { headers: data.headers } : {}),
      ...(data.heading ? { heading: data.heading } : {}),
      ...(data.bodyData ? { data: data.bodyData } : {}),
      ...(data.matchKeys ? { matchKeys: data.matchKeys } : {}),
      ...(data.images ? { images: data.images } : {})
    };

    let options = {
      method: 'POST',
      body: JSON.stringify(body)
    };

    return this.fetchNonJson(url, options)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }
  // Download Pdf for Shift Wise Report.
  downloadPdfForShiftWiseReport(filters, data) {
    let url = `${this.domain}/customreports/shiftwise/generatePdf?lineNames=${
      filters.lineNames
    }
    &shiftName=${filters.shiftName}&fromDateTime=${
      filters.fromDateTime
    }&toDateTime=${filters.toDateTime}&shiftStartTime=${
      filters.shiftStartTime
    }&shiftEndTime=${filters.shiftEndTime}`;

    let body = {
      ...(data.headers ? { headers: data.headers } : {}),
      ...(data.heading ? { heading: data.heading } : {}),
      ...(data.bodyData ? { data: data.bodyData } : {}),
      ...(data.matchKeys ? { matchKeys: data.matchKeys } : {}),
      ...(data.images ? { images: data.images } : {})
    };

    let options = {
      method: 'POST',
      body: JSON.stringify(body)
    };

    return this.fetchNonJson(url, options)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  // Download Pdf for MixerKW Report.
  downloadPdfForMixerKWReport(filters, data) {
    let url = `${
      this.domain
    }/customreports/mixerkwdischarge/generatePdf?lineNames=${filters.lineNames}
    &recipeName
    =${filters.recipeName}&fromDateTime=${filters.fromDateTime}&toDateTime=${
      filters.toDateTime
    }`;

    let body = {
      ...(data.headers ? { headers: data.headers } : {}),
      ...(data.heading ? { heading: data.heading } : {}),
      ...(data.bodyData ? { data: data.bodyData } : {}),
      ...(data.matchKeys ? { matchKeys: data.matchKeys } : {}),
      ...(data.images ? { images: data.images } : {})
    };

    let options = {
      method: 'POST',
      body: JSON.stringify(body)
    };

    return this.fetchNonJson(url, options)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  // Download pdf for Material Production
  generatePdfMaterialProductionReport(filters, data) {
    let url = `${
      this.domain
    }/customreports/materialproduction/generatePdf?lineNames=${
      filters.lineNames
    }
    &recipeName
    =${filters.recipeName}&fromDateTime=${filters.fromDateTime}&toDateTime=${
      filters.toDateTime
    }`;

    let body = {
      ...(data.headers ? { headers: data.headers } : {}),
      ...(data.heading ? { heading: data.heading } : {}),
      ...(data.bodyData ? { data: data.bodyData } : {}),
      ...(data.matchKeys ? { matchKeys: data.matchKeys } : {}),
      ...(data.images ? { images: data.images } : {})
    };

    let options = {
      method: 'POST',
      body: JSON.stringify(body)
    };

    return this.fetchNonJson(url, options)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  // Download pdf for Material Production
  generatePdfMaterialConsumptionReport(filters, data) {
    let url = `${
      this.domain
    }/customreports/materialconsumption/generatePdf?lineNames=${
      filters.lineNames
    }&fromDateTime=${filters.fromDateTime}&toDateTime=${filters.toDateTime}`;

    let body = {
      ...(data.headers ? { headers: data.headers } : {}),
      ...(data.heading ? { heading: data.heading } : {}),
      ...(data.bodyData ? { data: data.bodyData } : {}),
      ...(data.matchKeys ? { matchKeys: data.matchKeys } : {}),
      ...(data.images ? { images: data.images } : {})
    };

    let options = {
      method: 'POST',
      body: JSON.stringify(body)
    };

    return this.fetchNonJson(url, options)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  // get Batch Report
  BatchReport(batchId) {
    return this.fetch(
      `${this.domain}/customreports/batchreport?batchId=${batchId}`,
      {
        method: 'GET'
      }
    ).then(res => {
      return Promise.resolve(res);
    });
  }

  // Download Pdf for Batch Report.
  downloadPdfForBatchReport(filters, data) {
    let url = `${this.domain}/customreports/batchreport/generatePdf?lineNames=${
      filters.lineNames
    }&${filters.scheduleName ? `scheduleName=${filters.scheduleName}` : ''}&${
      filters.batchName ? `batchName=${filters.batchName}` : ''
    }`;

    let body = {
      ...(data.headers ? { headers: data.headers } : {}),
      ...(data.heading ? { heading: data.heading } : {}),
      ...(data.bodyData ? { data: data.bodyData } : {}),
      ...(data.matchKeys ? { matchKeys: data.matchKeys } : {})
    };

    let options = {
      method: 'POST',
      body: JSON.stringify(body)
    };

    return this.fetchNonJson(url, options)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  // Add New Line
  newLine(Line) {
    Line.createdBy = this.getProfile().userEmail;
    return this.fetch(`${this.domain}/api/lines/add`, {
      method: 'POST',
      body: JSON.stringify(Line)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Edit a Line.
  editLine(id, line) {
    line.updatedBy = this.getProfile().userEmail;

    return this.fetch(`${this.domain}/api/lines/edit/` + id, {
      method: 'POST',
      body: JSON.stringify(line)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Delete Line
  deleteLine(id) {
    let deleteData = {};
    deleteData.id = id;
    deleteData.deletedBy = this.getProfile().userEmail;

    return this.fetch(`${this.domain}/api/lines/delete`, {
      method: 'POST',
      body: JSON.stringify(deleteData)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Get a Particular Line
  getLine(id) {
    return this.fetch(`${this.domain}/api/lines/` + id, {
      method: 'GET'
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Material Category Crud // TODO: Move this to a seperate service folder.
  // Add new material categories.
  NewMaterialCategory(materialCategory) {
    materialCategory.createdBy = this.getProfile().userEmail;

    return this.fetch(`${this.domain}/api/materialcategories/add`, {
      method: 'POST',
      body: JSON.stringify(materialCategory)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Delete a material category based on the _id
  deleteMaterialCategory(id) {
    let deleteData = {};
    deleteData.id = id;
    deleteData.deletedBy = this.getProfile().userEmail;

    return this.fetch(`${this.domain}/api/materialcategories/delete`, {
      method: 'POST',
      body: JSON.stringify(deleteData)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Get  material category by id
  getMaterialCategory(id) {
    return this.fetch(`${this.domain}/api/materialcategories/` + id, {
      method: 'GET'
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Edit a material category based on the _id
  editMaterialCategory(id, materialCategory) {
    materialCategory.updatedBy = this.getProfile().userEmail;

    return this.fetch(`${this.domain}/api/materialcategories/edit/` + id, {
      method: 'POST',
      body: JSON.stringify(materialCategory)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Get all material categories.
  materialCategories(lineId = null) {
    if (lineId) {
      return this.fetch(`${this.domain}/api/materialcategories`, {
        method: 'POST',
        body: lineId === 'ALL' ? null : JSON.stringify({ lineId: lineId })
      }).then(res => {
        return Promise.resolve(res);
      });
    } else {
      return this.fetch(`${this.domain}/api/materialcategories`, {
        method: 'GET'
      }).then(res => {
        return Promise.resolve(res);
      });
    }
  }

  // Dosing and feeding -- Scales . Get all scales.
  scales(lineId) {
    if (lineId) {
      return this.fetch(`${this.domain}/api/scales`, {
        method: 'POST',
        body: JSON.stringify({ lineId: lineId })
      }).then(res => {
        return Promise.resolve(res);
      });
    } else {
      return this.fetch(`${this.domain}/api/scales`, {
        method: 'GET'
      }).then(res => {
        return Promise.resolve(res);
      });
    }
  }

  newScale(scale) {
    scale.createdBy = this.getProfile().userEmail;

    return this.fetch(`${this.domain}/api/scales/add`, {
      method: 'POST',
      body: JSON.stringify(scale)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Edit a scale
  editScale(id, scale) {
    scale.updatedBy = this.getProfile().userEmail;

    return this.fetch(`${this.domain}/api/scales/edit/` + id, {
      method: 'POST',
      body: JSON.stringify(scale)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Delete a scale based on the _id
  deleteScales(id) {
    let deleteData = {};
    deleteData.id = id;
    deleteData.deletedBy = this.getProfile().userEmail;

    return this.fetch(`${this.domain}/api/scales/delete`, {
      method: 'POST',
      body: JSON.stringify(deleteData)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Get particular scale
  getScale(id) {
    return this.fetch(`${this.domain}/api/scales/` + id, {
      method: 'GET'
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Bins
  bins(lineId) {
    if (lineId) {
      return this.fetch(`${this.domain}/api/bins`, {
        method: 'POST',
        body: JSON.stringify({ lineId: lineId })
      }).then(res => {
        return Promise.resolve(res);
      });
    } else {
      return this.fetch(`${this.domain}/api/bins`, {
        method: 'GET'
      }).then(res => {
        return Promise.resolve(res);
      });
    }
  }

  newBin(bin) {
    bin.createdBy = this.getProfile().userEmail;

    return this.fetch(`${this.domain}/api/bins/add`, {
      method: 'POST',
      body: JSON.stringify(bin)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Edit a bin
  editBin(id, bin) {
    bin.updatedBy = this.getProfile().userEmail;

    return this.fetch(`${this.domain}/api/bins/edit/` + id, {
      method: 'POST',
      body: JSON.stringify(bin)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Get particular Bin
  getBin(id) {
    return this.fetch(`${this.domain}/api/bins/` + id, {
      method: 'GET'
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Delete a Bin based on the _id
  deleteBin(id) {
    let deleteData = {};
    deleteData.id = id;
    deleteData.deletedBy = this.getProfile().userEmail;

    return this.fetch(`${this.domain}/api/bins/delete`, {
      method: 'POST',
      body: JSON.stringify(deleteData)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Feeding Points
  feedingPoints = (lineId = null, scaleId = null) => {
    let path = `${this.domain}/api/feedingpoints`;
    if (lineId && scaleId) {
      path += `?lineId=${lineId}&feedingPointPrimarySource=${scaleId}`;
    } else if (lineId) {
      path += `?lineId=${lineId}`;
    } else if (scaleId) {
      path += `?feedingPointPrimarySource=${scaleId}`;
    }

    return this.fetch(path, {
      method: 'GET'
    }).then(res => {
      return Promise.resolve(res);
    });
  };
  // Feeding PointsBy lineId
  feedingPointsByLine(lineId) {
    return this.fetch(`${this.domain}/api/feedingpoints?lineId=${lineId}`, {
      method: 'GET'
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  newFeedingPoint(feedingPoint) {
    feedingPoint.createdBy = this.getProfile().userEmail;

    return this.fetch(`${this.domain}/api/feedingpoints/add`, {
      method: 'POST',
      body: JSON.stringify(feedingPoint)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Delete FeedingPoint
  deleteFeedingPoint(id) {
    let deleteData = {};
    deleteData.id = id;
    deleteData.deletedBy = this.getProfile().userEmail;

    return this.fetch(`${this.domain}/api/feedingpoints/delete`, {
      method: 'POST',
      body: JSON.stringify(deleteData)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Get Feeding point data
  getFeedingPoint(id) {
    return this.fetch(`${this.domain}/api/feedingpoints/` + id, {
      method: 'GET'
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Edit a Feeding Point
  editFeedingPoint(id, feedingPoint) {
    feedingPoint.updatedBy = this.getProfile().userEmail;

    return this.fetch(`${this.domain}/api/feedingpoints/edit/` + id, {
      method: 'POST',
      body: JSON.stringify(feedingPoint)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Dosing Points
  dosingPoints(id = null) {
    let path;
    path = id
      ? `${this.domain}/api/dosingpoints?lineId=${id}`
      : `${this.domain}/api/dosingpoints`;
    return this.fetch(path, {
      method: 'GET'
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  newDosingPoint(dosingPoint) {
    dosingPoint.createdBy = this.getProfile().userEmail;

    return this.fetch(`${this.domain}/api/dosingpoints/add`, {
      method: 'POST',
      body: JSON.stringify(dosingPoint)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Delete FeedingPoint
  deleteDosingPoint(id) {
    let deleteData = {};
    deleteData.id = id;
    deleteData.deletedBy = this.getProfile().userEmail;

    return this.fetch(`${this.domain}/api/dosingpoints/delete`, {
      method: 'POST',
      body: JSON.stringify(deleteData)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Edit a Feeding Point
  editDosingPoint(id, dosingPoint) {
    dosingPoint.updatedBy = this.getProfile().userEmail;

    return this.fetch(`${this.domain}/api/dosingpoints/edit/` + id, {
      method: 'POST',
      body: JSON.stringify(dosingPoint)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Get Dosing Point
  getDosingPoint(id) {
    return this.fetch(`${this.domain}/api/dosingpoints/` + id, {
      method: 'GET'
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Action Parameters
  actionParameters = (lineId = null) => {
    if (lineId) {
      var lineData = { lineId: lineId ? lineId : null };
      return this.fetch(`${this.domain}/api/actionparameters`, {
        method: 'POST',
        body: JSON.stringify(lineData)
      }).then(res => {
        return Promise.resolve(res);
      });
    } else {
      return this.fetch(`${this.domain}/api/actionparameters`, {
        method: 'GET'
      }).then(res => {
        return Promise.resolve(res);
      });
    }
  };

  // Get Action Parameters based on line
  actionParametersByLine(lineId) {
    var lineData = { lineId: lineId ? lineId : null };
    return this.fetch(`${this.domain}/api/actionparameters`, {
      method: 'POST',
      body: JSON.stringify(lineData)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Adding new action Parameters.
  newActionParameters(actionParameters) {
    var actionParametersData = actionParameters.actionParameters;
    const user = this.getProfile().userEmail;
    // Push created by to each action parameters.
    for (var i = 0; i < actionParametersData.length; i++) {
      actionParametersData[i]['createdBy'] = user;
    }

    actionParameters.actionParameters = actionParametersData;
    delete actionParameters['lineName'];

    return this.fetch(`${this.domain}/api/actionparameters/add`, {
      method: 'POST',
      body: JSON.stringify(actionParameters)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  insertBulkActionsParameter = actionParameters => {
    actionParameters = _.map(actionParameters, o => {
      o.createdBy = this.getProfile().userEmail;
      return o;
    });
    return this.fetch(`${this.domain}/api/actionparameters/add/bulk`, {
      method: 'POST',
      body: JSON.stringify(actionParameters)
    }).then(res => {
      return Promise.resolve(res);
    });
  };

  insertBulkMixingActionsParameter = mixingActionParameters => {
    mixingActionParameters = _.map(mixingActionParameters, o => {
      o.createdBy = this.getProfile().userEmail;
      return o;
    });
    return this.fetch(`${this.domain}/api/mixingactions/add/bulk`, {
      method: 'POST',
      body: JSON.stringify(mixingActionParameters)
    }).then(res => {
      return Promise.resolve(res);
    });
  };

  insertBulkMixingModeParameter = mixingModeParameters => {
    mixingModeParameters = _.map(mixingModeParameters, o => {
      o.createdBy = this.getProfile().userEmail;
      return o;
    });
    return this.fetch(`${this.domain}/api/mixingmodes/add/bulk`, {
      method: 'POST',
      body: JSON.stringify(mixingModeParameters)
    }).then(res => {
      return Promise.resolve(res);
    });
  };

  // Delete Action Parameter
  deleteActionParameter(id) {
    let deleteData = {};
    deleteData.id = id;
    deleteData.deletedBy = this.getProfile().userEmail;

    return this.fetch(`${this.domain}/api/actionparameters/delete`, {
      method: 'POST',
      body: JSON.stringify(deleteData)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Edit Action Parameter
  editActionParameter(id, actionParameter) {
    actionParameter.updatedBy = this.getProfile().userEmail;

    return this.fetch(`${this.domain}/api/actionparameters/edit/` + id, {
      method: 'POST',
      body: JSON.stringify(actionParameter)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Get Action Parameter
  getActionParameter(id) {
    return this.fetch(`${this.domain}/api/actionparameters/` + id, {
      method: 'GET'
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Mixing Actions
  mixingActions = lineId => {
    if (lineId) {
      // Get all mixing actions for a particular Line ID.
      return this.fetch(`${this.domain}/api/mixingactions`, {
        method: 'POST',
        body: JSON.stringify({ lineId: lineId })
      }).then(res => {
        return Promise.resolve(res);
      });
    } else {
      return this.fetch(`${this.domain}/api/mixingactions`, {
        method: 'GET'
      }).then(res => {
        return Promise.resolve(res);
      });
    }
  };

  // New iram
  newIram(Iram) {
    Iram.createdBy = this.getProfile().userEmail;

    return this.fetch(`${this.domain}/api/iram/add/`, {
      method: 'POST',
      body: JSON.stringify(Iram)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Edit iram
  editIram(Iram, id) {
    Iram.updatedBy = this.getProfile().userEmail;

    return this.fetch(`${this.domain}/api/iram/edit/` + id, {
      method: 'POST',
      body: JSON.stringify(Iram)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // get all irams
  irams(lineId = null) {
    let path = lineId
      ? `${this.domain}/api/irams?lineId=${lineId}`
      : `${this.domain}/api/irams`;
    return this.fetch(path, {
      method: 'GET'
    }).then(res => {
      return Promise.resolve(res);
    });
  }
  // Get iRams based on line
  iramsByLine(lineId) {
    return this.fetch(`${this.domain}/api/irams?lineId=${lineId}`, {
      method: 'GET'
    }).then(res => {
      return Promise.resolve(res);
    });
  }
  // Get a Particular iram
  iram(id) {
    return this.fetch(`${this.domain}/api/iram/` + id, {
      method: 'GET'
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Delete Iram
  deleteIram(id) {
    let deleteData = {};
    deleteData.id = id;
    deleteData.deletedBy = this.getProfile().userEmail;

    return this.fetch(`${this.domain}/api/iram/delete`, {
      method: 'POST',
      body: JSON.stringify(deleteData)
    }).then(res => {
      return Promise.resolve(res);
    });
  }
  // New Action
  newAction(action) {
    action.createdBy = this.getProfile().userEmail;

    return this.fetch(`${this.domain}/api/mixingactions/add`, {
      method: 'POST',
      body: JSON.stringify(action)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Get a Particular Action
  getAction(id) {
    return this.fetch(`${this.domain}/api/mixingactions/` + id, {
      method: 'GET'
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Delete action
  deleteAction(id) {
    let deleteData = {};
    deleteData.id = id;
    deleteData.deletedBy = this.getProfile().userEmail;

    return this.fetch(`${this.domain}/api/mixingactions/delete`, {
      method: 'POST',
      body: JSON.stringify(deleteData)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Get a Particular Action
  // getAction(id) {
  //   return this.fetch(`${this.domain}/api/mixingactions/` + id, {
  //     method: 'GET'
  //   }).then(res => {
  //     return Promise.resolve(res);
  //   });
  // }

  // Edit action
  editAction(id, action) {
    action.updatedBy = this.getProfile().userEmail;

    return this.fetch(`${this.domain}/api/mixingactions/edit/` + id, {
      method: 'POST',
      body: JSON.stringify(action)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Mode Parameters
  modeParameters = lineId => {
    if (lineId) {
      var lineData = { lineId: lineId ? lineId : null };
      return this.fetch(`${this.domain}/api/modeparameters`, {
        method: 'POST',
        body: JSON.stringify(lineData)
      }).then(res => {
        return Promise.resolve(res);
      });
    } else {
      return this.fetch(`${this.domain}/api/modeparameters`, {
        method: 'GET'
      }).then(res => {
        return Promise.resolve(res);
      });
    }
  };

  // Get Action Parameters based on line
  modeParametersByLine(lineId) {
    var lineData = { lineId: lineId ? lineId : null };
    return this.fetch(`${this.domain}/api/modeparameters`, {
      method: 'POST',
      body: JSON.stringify(lineData)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Adding new mode Parameters.
  newModeParameters(modeParameters) {
    var modeParametersData = modeParameters.modeParameters;
    const user = this.getProfile().userEmail;
    // Push created by to each mode parameters.
    for (var i = 0; i < modeParametersData.length; i++) {
      modeParametersData[i]['createdBy'] = user;
    }

    modeParameters.modeParameters = modeParametersData;
    delete modeParameters['lineName'];

    return this.fetch(`${this.domain}/api/modeparameters/add`, {
      method: 'POST',
      body: JSON.stringify(modeParameters)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  insertBulkModeParameter = modeParameters => {
    modeParameters = _.map(modeParameters, o => {
      o.createdBy = this.getProfile().userEmail;
      return o;
    });
    return this.fetch(`${this.domain}/api/modeparameters/add/bulk`, {
      method: 'POST',
      body: JSON.stringify(modeParameters)
    }).then(res => {
      return Promise.resolve(res);
    });
  };

  // Edit Mode Parameter
  editModeParameter(id, modeParameter) {
    modeParameter.updatedBy = this.getProfile().userEmail;

    return this.fetch(`${this.domain}/api/modeparameters/edit/` + id, {
      method: 'POST',
      body: JSON.stringify(modeParameter)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Get Mode Parameter
  getModeParameter(id) {
    return this.fetch(`${this.domain}/api/modeparameters/` + id, {
      method: 'GET'
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Delete Mode Parameter
  deleteModeParameter(id) {
    let deleteData = {};
    deleteData.id = id;
    deleteData.deletedBy = this.getProfile().userEmail;

    return this.fetch(`${this.domain}/api/modeparameters/delete`, {
      method: 'POST',
      body: JSON.stringify(deleteData)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Get all modes
  mixingModes = lineId => {
    if (lineId) {
      // Get all modes for this particular lineId
      return this.fetch(`${this.domain}/api/mixingmodes`, {
        method: 'POST',
        body: JSON.stringify({ lineId: lineId })
      }).then(res => {
        return Promise.resolve(res);
      });
    } else {
      return this.fetch(`${this.domain}/api/mixingmodes`, {
        method: 'GET'
      }).then(res => {
        return Promise.resolve(res);
      });
    }
  };

  // Create new Mode
  newMode(mode) {
    mode.createdBy = this.getProfile().userEmail;

    return this.fetch(`${this.domain}/api/mixingmodes/add`, {
      method: 'POST',
      body: JSON.stringify(mode)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Get Particular Mode
  getMode(id) {
    return this.fetch(`${this.domain}/api/mixingmodes/` + id, {
      method: 'GET'
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  //Edit Modes
  editMode(id, mode) {
    mode.updatedBy = this.getProfile().userEmail;

    return this.fetch(`${this.domain}/api/mixingmodes/edit/` + id, {
      method: 'POST',
      body: JSON.stringify(mode)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  //Delete Mode
  deleteMode(id) {
    let deleteData = {};
    deleteData.id = id;
    deleteData.deletedBy = this.getProfile().userEmail;

    return this.fetch(`${this.domain}/api/mixingmodes/delete`, {
      method: 'POST',
      body: JSON.stringify(deleteData)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Miscellaneous Parameters
  miscellaneousParameters = lineId => {
    if (lineId) {
      return this.fetch(`${this.domain}/api/miscellaneousparameters`, {
        method: 'POST',
        body: JSON.stringify({ lineId: lineId })
      }).then(res => {
        return Promise.resolve(res);
      });
    } else {
      return this.fetch(`${this.domain}/api/miscellaneousparameters`, {
        method: 'GET'
      }).then(res => {
        return Promise.resolve(res);
      });
    }
  };

  // Add new miscellaneous parameter.
  newMiscellaneousParameter(miscellaneousParameter) {
    miscellaneousParameter.createdBy = this.getProfile().userEmail;

    return this.fetch(`${this.domain}/api/miscellaneousparameters/add`, {
      method: 'POST',
      body: JSON.stringify(miscellaneousParameter)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  insertBulkMiscellaneousParameter = miscellaneousParameters => {
    miscellaneousParameters = _.map(miscellaneousParameters, o => {
      o.createdBy = this.getProfile().userEmail;
      return o;
    });
    return this.fetch(`${this.domain}/api/miscellaneousparameters/add/bulk`, {
      method: 'POST',
      body: JSON.stringify(miscellaneousParameters)
    }).then(res => {
      return Promise.resolve(res);
    });
  };

  // Delete Miscellaneous Parameter.
  deleteMiscellaneousParam(id) {
    let deleteData = {};
    deleteData.id = id;
    deleteData.deletedBy = this.getProfile().userEmail;

    return this.fetch(`${this.domain}/api/miscellaneousparameters/delete`, {
      method: 'POST',
      body: JSON.stringify(deleteData)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Get Miscellaneous Parameter Data
  getMiscellaneousParamData(id) {
    return this.fetch(`${this.domain}/api/miscellaneousparameters/` + id, {
      method: 'GET'
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Edit Miscellaneous Parameter.
  editMiscellaneousParameter(id, miscellaneousParameter) {
    miscellaneousParameter.updatedBy = this.getProfile().userEmail;

    return this.fetch(`${this.domain}/api/miscellaneousparameters/edit/` + id, {
      method: 'POST',
      body: JSON.stringify(miscellaneousParameter)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Download Pdf for Miscellaneous Parameters.
  downloadPdfForMiscParams(filters, lineId) {
    let url = `${this.domain}/api/miscellaneousparameters?generatePdf=true`;

    let bodyData = {
      ...(filters.search ? { search: filters.search } : {}),
      ...(lineId ? { lineId: lineId } : {}),
      ...(filters.lineName ? { lineName: filters.lineName } : {})
    };

    let options = {
      method: 'POST',
      body: JSON.stringify(bodyData)
    };

    return this.fetchNonJson(url, options)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  // Engineering Parameters
  engineeringParameters = (id = null) => {
    let path;
    path = id
      ? `${this.domain}/api/engineeringparameters?lineId=${id}`
      : `${this.domain}/api/engineeringparameters`;
    return this.fetch(path, {
      method: 'GET'
    }).then(res => {
      return Promise.resolve(res);
    });
  };

  // get all material directory
  materialDirectory(materialCategoryId = null) {
    if (materialCategoryId) {
      return this.fetch(`${this.domain}/api/materialdirectory`, {
        method: 'POST',
        body: JSON.stringify({ materialCategoryId: materialCategoryId })
      }).then(res => {
        return Promise.resolve(res);
      });
    } else {
      return this.fetch(`${this.domain}/api/materialdirectory`, {
        method: 'GET'
      }).then(res => {
        return Promise.resolve(res);
      });
    }
  }

  // get All Materials by ScaleId
  materialDirectoryBySclaeId(scaleId) {
    return this.fetch(`${this.domain}/api/materialdirectory`, {
      method: 'POST',
      body: JSON.stringify({ scaleId: scaleId })
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // get Material By Id
  materialDirectoryById(id) {
    return this.fetch(`${this.domain}/api/materialdirectory/${id}`, {
      method: 'GET'
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Add new Material
  newMaterialDirectory(MaterialDirectory) {
    MaterialDirectory.createdBy = this.getProfile().userEmail;
    return this.fetch(`${this.domain}/api/materialdirectory/add`, {
      method: 'POST',
      body: JSON.stringify(MaterialDirectory)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  //delete  Material
  deleteMaterialDirectory(id) {
    let deleteData = {};
    deleteData.id = id;
    deleteData.deletedBy = this.getProfile().userEmail;
    return this.fetch(`${this.domain}/api/materialdirectory/delete`, {
      method: 'POST',
      body: JSON.stringify(deleteData)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Edit a Material
  editMaterialDirectory(id, MaterialDirectory) {
    MaterialDirectory.updatedBy = this.getProfile().userEmail;
    return this.fetch(`${this.domain}/api/materialdirectory/edit/` + id, {
      method: 'POST',
      body: JSON.stringify(MaterialDirectory)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // get bins with scale data
  binsWithScale() {
    return this.fetch(`${this.domain}/api/binswithscale`, {
      method: 'GET'
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // add Material to bins ( bin assignment )
  addMaterial(Bins) {
    return this.fetch(`${this.domain}/api/bins/addmaterial`, {
      method: 'POST',
      body: JSON.stringify({ binMaterials: Bins })
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Get scales by material category Id.
  getAllScalesByMaterialCategoryIdAndLineId(materialCategoryId, lineId) {
    return this.fetch(
      `${this.domain}/api/materialCategories/${materialCategoryId}/scales`,
      {
        method: 'POST',
        body: JSON.stringify({ lineId: lineId })
      }
    )
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  // Get all Recipe Weighings.
  getAllRecipeWeighings(recipeId) {
    return this.fetch(`${this.domain}/api/recipeweighings/${recipeId}`, {
      method: 'GET'
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Delete recipe weighing for a particular Id
  deleteWeighings(recipeId, scaleId) {
    let apiObject = {};
    apiObject.id = recipeId;
    apiObject.scaleId = scaleId;
    apiObject.deletedBy = this.getProfile().userEmail;

    return this.fetch(`${this.domain}/api/recipeweighings/delete`, {
      method: 'POST',
      body: JSON.stringify(apiObject)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Add Recipe Weighing
  addRecipeWeighing(recipe) {
    // updated by inside weighingItems not dynamic single edit as of now
    for (var i = 0; i < recipe.weighingItems.length; i++) {
      recipe.weighingItems[i].createdBy = this.getProfile().userEmail;
    }
    return this.fetch(`${this.domain}/api/recipeweighings/add/`, {
      method: 'POST',
      body: JSON.stringify(recipe)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Edit Recipe Weighing
  editRecipeWeighing(recipe) {
    // updated by inside weighingItems not dynamic single edit as of now
    for (var i = 0; i < recipe.weighingItems.length; i++) {
      recipe.weighingItems[i].updatedBy = this.getProfile().userEmail;
    }

    return this.fetch(`${this.domain}/api/recipeweighings/edit/`, {
      method: 'POST',
      body: JSON.stringify(recipe)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  //validate Weighing by RecipeId
  validateWeighing(id) {
    let recipe = { recipeId: id };

    return this.fetch(`${this.domain}/api/recipeweighings/validaterecipe`, {
      method: 'POST',
      body: JSON.stringify(recipe)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Get all mixing parameters.
  getAllRecipeMixings(recipeId) {
    return this.fetch(`${this.domain}/api/recipemixings/${recipeId}`, {
      method: 'GET'
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Add Recipe Mixings
  addRecipeMixing(recipeMixingObject) {
    // Editing format to send it to the server.
    for (var i = 0; i < recipeMixingObject.mixingItems.length; i++) {
      recipeMixingObject.mixingItems[i].createdBy = this.getProfile().userEmail;
    }

    return this.fetch(`${this.domain}/api/recipemixings/add`, {
      method: 'POST',
      body: JSON.stringify(recipeMixingObject)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Edit Recipe Mixing
  editRecipeMixing(recipeMixingObject) {
    // Editing format to send it to the server.
    for (var i = 0; i < recipeMixingObject.mixingItems.length; i++) {
      recipeMixingObject.mixingItems[i].updatedBy = this.getProfile().userEmail;
    }

    return this.fetch(`${this.domain}/api/recipemixings/edit`, {
      method: 'POST',
      body: JSON.stringify(recipeMixingObject)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Delete Recipe Mixing
  deleteMixing(id, recipeId) {
    let mixingDelete = {};
    mixingDelete = { id: id, recipeId };
    mixingDelete.deletedBy = this.getProfile().userEmail;

    return this.fetch(`${this.domain}/api/recipemixings/delete`, {
      method: 'POST',
      body: JSON.stringify(mixingDelete)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  //validate Mixing by RecipeId
  validateMixing(payload) {
    return this.fetch(`${this.domain}/api/recipemixings/validaterecipe`, {
      method: 'POST',
      body: JSON.stringify(payload)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Add Recipe Miscellaneous.
  addRecipeMiscellaneous(recipeMiscellaneousData) {
    recipeMiscellaneousData.createdBy = this.getProfile().userEmail;
    return this.fetch(`${this.domain}/api/recipemiscellaneous/add`, {
      method: 'POST',
      body: JSON.stringify(recipeMiscellaneousData)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Edit Recipe Miscellaneous.
  editRecipeMiscellaneous(recipeMiscellaneousData) {
    recipeMiscellaneousData.updatedBy = this.getProfile().userEmail;

    return this.fetch(`${this.domain}/api/recipemiscellaneous/edit`, {
      method: 'POST',
      body: JSON.stringify(recipeMiscellaneousData)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Get all Recipe Miscellaneous Data
  getAllRecipeMiscellaneous(recipeId) {
    return this.fetch(`${this.domain}/api/recipemiscellaneous/${recipeId}`, {
      method: 'GET'
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Edit Recipe Weighing
  createNewRecipeVerison(fullRecipe) {
    fullRecipe.recipe.createdBy = this.getProfile().userEmail;
    return this.fetch(`${this.domain}/api/recipes/createnewrecipeverison`, {
      method: 'POST',
      body: JSON.stringify(fullRecipe)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Adding new Engineering Parameters.
  newEngineeringParameter(engineeringParameters) {
    engineeringParameters.createdBy = this.getProfile().userEmail;

    return this.fetch(`${this.domain}/api/engineeringparameters/add`, {
      method: 'POST',
      body: JSON.stringify(engineeringParameters)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  insertBulkEngineeringParameter = engineeringParameters => {
    engineeringParameters = _.map(engineeringParameters, o => {
      o.createdBy = this.getProfile().userEmail;
      return o;
    });
    return this.fetch(`${this.domain}/api/engineeringparameters/add/bulk`, {
      method: 'POST',
      body: JSON.stringify(engineeringParameters)
    }).then(res => {
      return Promise.resolve(res);
    });
  };

  // Delete Engineering Parameter.
  deleteEngineeringParameter(id) {
    let deleteData = {};
    deleteData.id = id;
    deleteData.deletedBy = this.getProfile().userEmail;

    return this.fetch(`${this.domain}/api/engineeringParameters/delete`, {
      method: 'POST',
      body: JSON.stringify(deleteData)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Download Pdf for Engineering Parameters.
  downloadPdfForEngineeringParams(filters, lineId) {
    let url = `${this.domain}/api/engineeringParameters?generatePdf=true`;

    if (lineId) {
      url += `&lineId=${lineId}`;
    }

    if (filters.search) {
      url += `&search=${filters.search}`;
    }

    if (filters.lineName) {
      url += `&lineName=${filters.lineName}`;
    }

    let options = {
      method: 'GET'
    };

    return this.fetchNonJson(url, options)
      .then(this._checkStatus.bind(this))
      .then(res => {
        if (res) {
          return Promise.resolve(res);
        } else {
          return Promise.reject(res.message);
        }
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  // Get Engineering Parameter
  getEngineeringParamData(id) {
    return this.fetch(`${this.domain}/api/engineeringparameters/` + id, {
      method: 'GET'
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Edit Miscellaneous Parameter.
  editEngineeringParameter(id, engineeringParameter) {
    engineeringParameter.updatedBy = this.getProfile().userEmail;

    return this.fetch(`${this.domain}/api/engineeringparameters/edit/` + id, {
      method: 'POST',
      body: JSON.stringify(engineeringParameter)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Report Parameters
  reportParameters = (id = null, isDownstream) => {
    let path;
    if (id && isDownstream) {
      path = `${
        this.domain
      }/api/reportparameters?lineId=${id}&isDownstream=true`;
    } else if (id) {
      path = `${this.domain}/api/reportparameters?lineId=${id}`;
    } else {
      path = `${this.domain}/api/reportparameters`;
    }
    return this.fetch(path, {
      method: 'GET'
    }).then(res => {
      return Promise.resolve(res);
    });
  };

  // Edit Report Parameter
  editReportParameter(id, reportParameter) {
    reportParameter.updatedBy = this.getProfile().userEmail;

    return this.fetch(`${this.domain}/api/reportparameters/edit/` + id, {
      method: 'POST',
      body: JSON.stringify(reportParameter)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  //Delete Report Parameter
  deleteReportParamete(id) {
    let deleteData = {};
    deleteData.id = id;
    deleteData.deletedBy = this.getProfile().userEmail;
    return this.fetch(`${this.domain}/api/reportparameters/delete`, {
      method: 'POST',
      body: JSON.stringify(deleteData)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  reportParameter(reportParameterId) {
    return this.fetch(
      `${this.domain}/api/reportparameters/${reportParameterId}`,
      {
        method: 'GET'
      }
    ).then(res => {
      return Promise.resolve(res);
    });
  }

  // Download Pdf for Report Parameters.
  downloadPdfForReportParams(filters, lineId) {
    let url = `${this.domain}/api/reportparameters?generatePdf=true`;

    if (lineId) {
      url += `&lineId=${lineId}`;
    }

    if (filters.search) {
      url += `&search=${filters.search}`;
    }

    if (filters.lineName) {
      url += `&lineName=${filters.lineName}`;
    }

    let options = {
      method: 'GET'
    };

    return this.fetchNonJson(url, options)
      .then(this._checkStatus.bind(this))
      .then(res => {
        if (res) {
          return Promise.resolve(res);
        } else {
          return Promise.reject(res.message);
        }
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  // List all recipes
  recipes(lineId) {
    if (lineId) {
      return this.fetch(`${this.domain}/api/recipes`, {
        method: 'POST',
        body: JSON.stringify({ lineId: lineId })
      }).then(res => {
        return Promise.resolve(res);
      });
    } else {
      return this.fetch(`${this.domain}/api/recipes`, {
        method: 'GET'
      }).then(res => {
        return Promise.resolve(res);
      });
    }
  }
  // List all recipes
  releasedRecipes(lineId) {
    if (lineId) {
      return this.fetch(`${this.domain}/api/releasedrecipes`, {
        method: 'POST',
        body: JSON.stringify({ lineId: lineId })
      }).then(res => {
        return Promise.resolve(res);
      });
    } else {
      return this.fetch(`${this.domain}/api/releasedrecipes`, {
        method: 'GET'
      }).then(res => {
        return Promise.resolve(res);
      });
    }
  }

  latestUnreleasedRecipes(lineId) {
    if (lineId) {
      return this.fetch(`${this.domain}/api/unreleasedrecipes`, {
        method: 'POST',
        body: JSON.stringify({ lineId: lineId })
      }).then(res => {
        return Promise.resolve(res);
      });
    } else {
      return this.fetch(`${this.domain}/api/unreleasedrecipes`, {
        method: 'GET'
      }).then(res => {
        return Promise.resolve(res);
      });
    }
  }
  // Create new recipe
  newRecipe(recipe) {
    recipe.createdBy = this.getProfile().userEmail;

    return this.fetch(`${this.domain}/api/recipes/add`, {
      method: 'POST',
      body: JSON.stringify(recipe)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // // Delete a Recipe
  // deleteRecipe(id) { //Change fn name
  //   let recipeDelete = {};
  //   recipeDelete = { id: id };
  //   recipeDelete.deletedBy = this.getProfile().userEmail;

  //   return this.fetch(`${this.domain}/api/recipes/delete`, {
  //     method: 'POST',
  //     body: JSON.stringify(recipeDelete)
  //   }).then(res => {
  //     return Promise.resolve(res);
  //   });
  // }

  // Delete a Recipe Group
  deleteRecipe(id) {
    let recipeGroupDelete = {};
    recipeGroupDelete = { id: id };
    recipeGroupDelete.deletedBy = this.getProfile().userEmail;

    return this.fetch(`${this.domain}/api/recipes/deleterecipegroup`, {
      method: 'POST',
      body: JSON.stringify(recipeGroupDelete)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  //Clone a Recipe (Save As Recipe)
  saveAsRecipe(id, recipe) {
    return this.fetch(`${this.domain}/api/recipes/${id}/saveAs`, {
      method: 'PUT',
      body: JSON.stringify(recipe)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Get all information of the recipe.
  recipe(id) {
    // Logic to get a particular recipe.
    return this.fetch(`${this.domain}/api/recipes/` + id, {
      method: 'GET'
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Download Pdf for Recipe.
  downloadPdfForViewRecipe(filters, data) {
    let url = `${this.domain}/api/recipes/generatePdf`;

    let body = {
      ...(data.headers ? { headers: data.headers } : {}),
      ...(data.heading ? { heading: data.heading } : {}),
      ...(data.bodyData ? { data: data.bodyData } : {}),
      ...(data.matchKeys ? { matchKeys: data.matchKeys } : {}),
      ...(data.images ? { images: data.images } : {})
    };

    let options = {
      method: 'POST',
      body: JSON.stringify(body)
    };

    return this.fetchNonJson(url, options)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  // get latest recipe based on GroupId
  latestRecipe(groupId) {
    // Logic to get a particular recipe.
    return this.fetch(`${this.domain}/api/latestrecipe/` + groupId, {
      method: 'GET'
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // get all versions of recipe of a  GroupId
  allRecipeVersions(groupId) {
    // Logic to get a particular recipe.
    return this.fetch(`${this.domain}/api/allrecipeversions/` + groupId, {
      method: 'GET'
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Edit recipe.
  editRecipe(recipe, id) {
    recipe.updatedBy = this.getProfile().userEmail;

    return this.fetch(`${this.domain}/api/recipes/edit/${id}`, {
      method: 'POST',
      body: JSON.stringify(recipe)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Release  recipe.
  releaseRecipe(id) {
    let recipe = { id: id };
    return this.fetch(`${this.domain}/api/recipes/release`, {
      method: 'POST',
      body: JSON.stringify(recipe)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Revert Release  recipe.
  revertReleaseRecipe(id) {
    let recipe = { id: id };
    return this.fetch(`${this.domain}/api/recipes/revertrelease`, {
      method: 'POST',
      body: JSON.stringify(recipe)
    })
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  // Adding new Report Parameters.
  newReportParameter(reportParameter) {
    reportParameter.createdBy = this.getProfile().userEmail;
    return this.fetch(`${this.domain}/api/reportparameters/add`, {
      method: 'POST',
      body: JSON.stringify(reportParameter)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  insertBulkReportParameter = reportParameters => {
    reportParameters = _.map(reportParameters, o => {
      o.createdBy = this.getProfile().userEmail;
      return o;
    });
    return this.fetch(`${this.domain}/api/reportparameters/add/bulk`, {
      method: 'POST',
      body: JSON.stringify(reportParameters)
    }).then(res => {
      return Promise.resolve(res);
    });
  };

  //Add New ProductionSchedule
  newProductionSchedule(productionSchedule) {
    productionSchedule.createdBy = this.getProfile().userEmail;
    return this.fetch(`${this.domain}/api/productionschedules/add`, {
      method: 'POST',
      body: JSON.stringify(productionSchedule)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Edit  ProductionSchedule
  editProductionSchedule(productionSchedule, id) {
    productionSchedule.updatedBy = this.getProfile().userEmail;
    return this.fetch(`${this.domain}/api/productionschedules/edit/${id}`, {
      method: 'POST',
      body: JSON.stringify(productionSchedule)
    }).then(res => {
      return Promise.resolve(res);
    });
  }
  // delete  ProductionSchedule
  deleteProductionSchedule(id) {
    let recipeDelete = {};
    recipeDelete = { id: id };
    recipeDelete.deletedBy = this.getProfile().userEmail;
    return this.fetch(`${this.domain}/api/productionschedules/delete`, {
      method: 'POST',
      body: JSON.stringify(recipeDelete)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Get one productionSchedule information .
  productionSchedule(id) {
    return this.fetch(`${this.domain}/api/productionschedules/` + id, {
      method: 'GET'
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // get bin history details
  getBinHistoryData(lineId, fromDateTime, toDateTime, binId) {
    let path = binId
      ? `${
          this.domain
        }/api/bins/getmaterialhistory?lineId=${lineId}&fromDateTime=${fromDateTime}&toDateTime=${toDateTime}&binId=${binId}`
      : `${
          this.domain
        }/api/bins/getmaterialhistory?lineId=${lineId}&fromDateTime=${fromDateTime}&toDateTime=${toDateTime}`;
    return this.fetch(path, {
      method: 'GET'
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // get all recipe by lineid and time
  getAllRecipesByLineAndTime(lineId, fromDateTime, toDateTime) {
    return this.fetch(
      `${
        this.domain
      }/api/getallrecipebylineandtime?lineId=${lineId}&fromDateTime=${fromDateTime}&toDateTime=${toDateTime}`,
      {
        method: 'GET'
      }
    ).then(res => {
      return Promise.resolve(res);
    });
  }

  // Get all the production schedule by recipeId
  productionScheduleByRecipe(recipeId, fromDateTime, toDateTime) {
    return this.fetch(
      `${
        this.domain
      }/api/productionschedulesbyrecipe?recipeId=${recipeId}&fromDateTime=${fromDateTime}&toDateTime=${toDateTime}`,
      {
        method: 'GET'
      }
    ).then(res => {
      return Promise.resolve(res);
    });
  }

  // Get all the production schedule by recipeId
  productionScheduleList(lineId, status) {
    if (lineId && status) {
      return this.fetch(
        `${
          this.domain
        }/api/productionschedulelist?lineId=${lineId}&status=${status}`,
        {
          method: 'GET'
        }
      ).then(res => {
        return Promise.resolve(res);
      });
    } else if (lineId) {
      return this.fetch(
        `${this.domain}/api/productionschedulelist?lineId=${lineId}`,
        {
          method: 'GET'
        }
      ).then(res => {
        return Promise.resolve(res);
      });
    } else if (status) {
      return this.fetch(
        `${this.domain}/api/productionschedulelist?status=${status}`,
        {
          method: 'GET'
        }
      ).then(res => {
        return Promise.resolve(res);
      });
    } else {
      return this.fetch(`${this.domain}/api/productionschedulelist`, {
        method: 'GET'
      }).then(res => {
        return Promise.resolve(res);
      });
    }
  }

  // get all   ProductionSchedule by lineId or not
  productionSchedules(lineId, fromDateTime, toDateTime, status) {
    if (lineId && fromDateTime && toDateTime) {
      return this.fetch(
        `${
          this.domain
        }/api/productionschedules?lineId=${lineId}&fromDateTime=${fromDateTime}&toDateTime=${toDateTime}`,
        {
          method: 'GET'
        }
      ).then(res => {
        return Promise.resolve(res);
      });
    } else if (lineId && status) {
      return this.fetch(
        `${
          this.domain
        }/api/productionschedules?lineId=${lineId}&status=${status}`,
        {
          method: 'GET'
        }
      ).then(res => {
        return Promise.resolve(res);
      });
    } else if (lineId) {
      return this.fetch(
        `${
          this.domain
        }/api/productionschedules?lineIds=${lineId}&status=COMPLETE`,
        {
          method: 'GET'
        }
      ).then(res => {
        return Promise.resolve(res);
      });
    } else if (status) {
      return this.fetch(
        `${this.domain}/api/productionschedules?status=${status}`,
        {
          method: 'GET'
        }
      ).then(res => {
        return Promise.resolve(res);
      });
    } else {
      return this.fetch(`${this.domain}/api/productionschedules`, {
        method: 'GET'
      }).then(res => {
        return Promise.resolve(res);
      });
    }
  }

  // Get total consumed weight.
  totalConsumedWeight(date, lineId, groupBy) {
    return this.fetch(
      `${
        this.domain
      }/api/consumption/totalweightgroupedby?lineId=${lineId}&date=${date}&groupBy=${groupBy}`,
      {
        method: 'GET'
      }
    ).then(res => {
      return Promise.resolve(res);
    });
  }

  // Get total consumed weight.
  getParametersByBatches(batchId) {
    return this.fetch(
      `${this.domain}/api/batches/getparametersbybatches?batchId=${batchId}`,
      {
        method: 'GET'
      }
    ).then(res => {
      return Promise.resolve(res);
    });
  }

  // Get consumption statistic by orderId
  getConsumptionStatisticsByOrderId(batchIds, orderId, materialId, recipeId) {
    return this.fetch(
      `${
        this.domain
      }/api/consumption/getconsumptionstatsbyorderwise?batchIds=${batchIds.join(
        ','
      )}&orderId=${orderId}&materialId=${materialId}&recipeId=${recipeId}`,
      {
        method: 'GET'
      }
    ).then(res => {
      return Promise.resolve(res);
    });
  }

  // get report by all byMaterialCatagory
  byMaterialCatagory(lineId, fromDateTime, toDateTime) {
    return this.fetch(
      `${this.domain}/api/consumption/getconsumptionbymaterialcatagory?${
        lineId === 'ALL' ? '' : `lineId=${lineId}&`
      }fromDateTime=${fromDateTime}&toDateTime=${toDateTime}`,
      {
        method: 'GET'
      }
    ).then(res => {
      return Promise.resolve(res);
    });
  }

  // Download Pdf for Material Category Consumption.
  downloadPdfForMatCatConsumption(filters, data) {
    let url = `${
      this.domain
    }/api/consumption/getconsumptionbymaterialcatagory/generatePdf?${
      filters.lineName === 'ALL' ? '' : `lineName=${filters.lineName}`
    }&fromDateTime=${filters.fromDateTime}&toDateTime=${filters.toDateTime}&${
      filters.searchKey ? `searchKey=${filters.searchKey}` : ''
    }`;

    let body = {
      ...(data.headers ? { headers: data.headers } : {}),
      ...(data.heading ? { heading: data.heading } : {}),
      ...(data.bodyData ? { data: data.bodyData } : {}),
      ...(data.matchKeys ? { matchKeys: data.matchKeys } : {}),
      ...(data.images ? { images: data.images } : {})
    };

    let options = {
      method: 'POST',
      body: JSON.stringify(body)
    };

    return this.fetchNonJson(url, options)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  // get report by one byMaterial
  byMaterial(lineId, fromDateTime, toDateTime, materialdirectoryId) {
    materialdirectoryId = materialdirectoryId ? materialdirectoryId : '';
    return this.fetch(
      `${
        this.domain
      }/api/consumption/getconsumtionsbyshifts?lineId=${lineId}&materialdirectoryId=${materialdirectoryId}&fromDateTime=${fromDateTime}&toDateTime=${toDateTime}`,
      {
        method: 'GET'
      }
    ).then(res => {
      return Promise.resolve(res);
    });
  }

  // get report by All byMaterial
  byMaterials(lineId, fromDateTime, toDateTime, materialCategoryId) {
    materialCategoryId = materialCategoryId ? materialCategoryId : '';
    return this.fetch(
      `${this.domain}/api/consumption/getconsumtionsbyshifts?${
        lineId === 'ALL' ? '' : `lineId=${lineId}&`
      }materialCategoryId=${materialCategoryId}&fromDateTime=${fromDateTime}&toDateTime=${toDateTime}`,
      {
        method: 'GET'
      }
    ).then(res => {
      return Promise.resolve(res);
    });
  }

  defaultFromAndToTime(lineId) {
    return this.fetch(
      `${this.domain}/api/shifts/defaultfromtodate?${
        lineId === undefined || lineId === 'ALL' ? '' : `lineId=${lineId}`
      }`,
      {
        method: 'GET'
      }
    ).then(res => {
      return Promise.resolve(res);
    });
  }

  // Download Pdf for Material Consumption.
  downloadPdfForMatConsumption(filters, data) {
    let url = `${
      this.domain
    }/api/consumption/getconsumtionsbyshifts/generatePdf?${
      filters.lineName === 'ALL' ? '' : `lineName=${filters.lineName}`
    }&fromDateTime=${filters.fromDateTime}&toDateTime=${filters.toDateTime}&${
      filters.searchKey ? `searchKey=${filters.searchKey}` : ''
    }&materialCategoryName=${filters.materialCategoryName}`;

    let body = {
      ...(data.headers ? { headers: data.headers } : {}),
      ...(data.heading ? { heading: data.heading } : {}),
      ...(data.bodyData ? { data: data.bodyData } : {}),
      ...(data.matchKeys ? { matchKeys: data.matchKeys } : {}),
      ...(data.images ? { images: data.images } : {})
    };

    let options = {
      method: 'POST',
      body: JSON.stringify(body)
    };

    return this.fetchNonJson(url, options)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  // get consuption report by all Batches
  byBatches(lineId, fromDateTime, toDateTime) {
    return this.fetch(
      `${this.domain}/api/consumption/getconsumtionsbycharges?${
        lineId === 'ALL' ? '' : `lineId=${lineId}&`
      }fromDateTime=${fromDateTime}&toDateTime=${toDateTime}`,
      {
        method: 'GET'
      }
    ).then(res => {
      return Promise.resolve(res);
    });
  }

  // Download Pdf for Batch Consumption.
  downloadPdfForBatchConsumption(filters, data) {
    let url = `${
      this.domain
    }/api/consumption/getconsumtionsbycharges/generatePdf?${
      filters.lineName === 'ALL' ? '' : `lineName=${filters.lineName}`
    }&fromDateTime=${filters.fromDateTime}&toDateTime=${filters.toDateTime}&${
      filters.searchKey ? `searchKey=${filters.searchKey}` : ''
    }&batchName=${filters.batchName}&recipeName=${
      filters.recipeName
    }&scheduleName=${filters.scheduleName}`;

    let body = {
      ...(data.headers ? { headers: data.headers } : {}),
      ...(data.heading ? { heading: data.heading } : {}),
      ...(data.bodyData ? { data: data.bodyData } : {}),
      ...(data.matchKeys ? { matchKeys: data.matchKeys } : {}),
      ...(data.images ? { images: data.images } : {})
    };

    let options = {
      method: 'POST',
      body: JSON.stringify(body)
    };

    return this.fetchNonJson(url, options)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  // get consuption report by all Batches
  getConsumptionStatistics(lineId, fromDateTime, toDateTime) {
    return this.fetch(
      `${this.domain}/api/consumption/getconsumptionstats?${
        lineId === 'ALL' ? '' : `lineId=${lineId}&`
      }fromDateTime=${fromDateTime}&toDateTime=${toDateTime}`,
      {
        method: 'GET'
      }
    ).then(res => {
      return Promise.resolve(res);
    });
  }

  // Download Pdf for Consumption stats.
  downloadPdfForStatsConsumption(filters, data) {
    let url = `${
      this.domain
    }/api/dashboard/reportdashboarddetails/generatePdf?${
      filters.lineName === 'ALL' ? '' : `lineName=${filters.lineName}`
    }&fromDateTime=${filters.fromDateTime}&toDateTime=${filters.toDateTime}&${
      filters.searchKey ? `searchKey=${filters.searchKey}` : ''
    }&batchName=${filters.batchName}&recipeName=${
      filters.recipeName
    }&scheduleName=${filters.scheduleName}`;

    let body = {
      ...(data.headers ? { headers: data.headers } : {}),
      ...(data.heading ? { heading: data.heading } : {}),
      ...(data.bodyData ? { data: data.bodyData } : {}),
      ...(data.matchKeys ? { matchKeys: data.matchKeys } : {}),
      ...(data.images ? { images: data.images } : {})
    };

    let options = {
      method: 'POST',
      body: JSON.stringify(body)
    };

    return this.fetchNonJson(url, options)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  // get Production report by all Recipe
  productionByRecipe(lineId, fromDateTime, toDateTime, shiftId) {
    return this.fetch(
      `${this.domain}/api/production/getproductionbyrecipe?${
        lineId === 'ALL' ? '' : `lineId=${lineId}&`
      }fromDateTime=${fromDateTime}&toDateTime=${toDateTime}${
        shiftId == 0 ? '' : `&shiftId=${shiftId}`
      }`,
      {
        method: 'GET'
      }
    ).then(res => {
      return Promise.resolve(res);
    });
  }

  // Download Pdf for Recipe production.
  downloadPdfForRecipeProduction(filters, data) {
    let url = `${
      this.domain
    }/api/production/getproductionbyrecipe/generatePdf?${
      filters.lineName === 'ALL' ? '' : `lineName=${filters.lineName}`
    }&fromDateTime=${filters.fromDateTime}&toDateTime=${filters.toDateTime}&${
      filters.searchKey ? `searchKey=${filters.searchKey}` : ''
    }&recipeName=${filters.recipeName}&scheduleName=${
      filters.scheduleId
    }&totalBatches=${filters.totalBatches}&totalWeight=${filters.totalWeight}${
      filters.shiftName ? `&shiftName=${filters.shiftName}` : ''
    }`;

    let body = {
      ...(data.headers ? { headers: data.headers } : {}),
      ...(data.heading ? { heading: data.heading } : {}),
      ...(data.bodyData ? { data: data.bodyData } : {}),
      ...(data.matchKeys ? { matchKeys: data.matchKeys } : {}),
      ...(data.images ? { images: data.images } : {})
    };

    let options = {
      method: 'POST',
      body: JSON.stringify(body)
    };

    return this.fetchNonJson(url, options)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  // get Production report by Time(day, month,hour)
  productionByTime(lineId, productionDate, groupBy) {
    return this.fetch(
      `${this.domain}/api/production/productionbytime?${
        lineId === 'ALL' ? '' : `lineId=${lineId}&`
      }date=${productionDate}&groupBy=${groupBy}`,
      {
        method: 'GET'
      }
    ).then(res => {
      return Promise.resolve(res);
    });
  }

  // Get all Engineering Parameters.
  parameterGroups(lineId, groupType) {
    return this.fetch(
      `${
        this.domain
      }/api/parametergroups?lineId=${lineId}&groupType=${groupType}`,
      {
        method: 'GET'
      }
    ).then(res => {
      return Promise.resolve(res);
    });
  }

  // Create new parameter group
  newParameterGroup(parameterGroup) {
    parameterGroup.createdBy = this.getProfile().userEmail;
    return this.fetch(`${this.domain}/api/parametergroups/add`, {
      method: 'POST',
      body: JSON.stringify(parameterGroup)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // get batch by productionScheduleId
  batchByProductionSchedule(productionScheduleId, recipeId) {
    let url = recipeId
      ? `${
          this.domain
        }/api/batches?productionScheduleId=${productionScheduleId}&recipeId=${recipeId}&status=COMPLETE`
      : `${
          this.domain
        }/api/batches?productionScheduleId=${productionScheduleId}&status=COMPLETE`;

    return this.fetch(url, {
      method: 'GET'
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // get diagnostics by batchId
  batchDiagnostics(batchId, reportParameterIds = null) {
    let url = `${
      this.domain
    }/api/batches/getbatchdiagnostics?batchId=${batchId}`;
    if (reportParameterIds) {
      url = url + `&reportParameterIds=${reportParameterIds}`;
    }
    return this.fetch(url, {
      method: 'GET'
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  downstreams(lineId, fromDateTime, toDateTime, reportParameterIds) {
    let url = `${
      this.domain
    }/api/batches/getdownstreams?lineId=${lineId}&fromDateTime=${fromDateTime}&toDateTime=${toDateTime}&reportParameterIds=${reportParameterIds}`;
    return this.fetch(url, {
      method: 'GET'
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Download Pdf for Downstream Parameters.
  generatePdfFromDownstreamParams(filters, data) {
    let url = `${this.domain}/api/batches/getdownstreams/generatePdf?${
      filters.lineName ? `lineName=${filters.lineName}` : ''
    }&fromDateTime=${filters.fromDateTime}&toDateTime=${filters.toDateTime}&${
      filters.reportParameters
        ? `reportParameters=${filters.reportParameters}`
        : ''
    }&${
      filters.parameterSearchKey
        ? `parameterSearchKey=${filters.parameterSearchKey}`
        : ''
    }`;

    let body = {
      ...(data.headers ? { headers: data.headers } : {}),
      ...(data.title ? { title: data.title } : {}),
      ...(data.heading ? { heading: data.heading } : {}),
      ...(data.bodyData ? { data: data.bodyData } : {}),
      ...(data.matchKeys ? { matchKeys: data.matchKeys } : {}),
      ...(data.images ? { images: data.images } : {})
    };

    let options = {
      method: 'POST',
      body: JSON.stringify(body)
    };

    return this.fetchNonJson(url, options)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  // get diagnostics by batchId
  detailedBatchReportGraph(batchId, lineId, detailedBatchReport) {
    return this.fetch(
      `${
        this.domain
      }/api/batches/getbatchdiagnostics?lineId=${lineId}&batchId=${batchId}&detailedBatchReport=${detailedBatchReport}`,
      {
        method: 'GET'
      }
    ).then(res => {
      return Promise.resolve(res);
    });
  }

  // Download Pdf for Diagnostics Parameters.
  downloadPdfForDiagParams(filters, data) {
    let url = `${this.domain}/api/batches/getbatchdiagnostics/generatePdf?${
      filters.lineName ? `lineName=${filters.lineName}` : ''
    }&fromDateTime=${filters.fromDateTime}&toDateTime=${filters.toDateTime}&${
      filters.productionScheduleName
        ? `productionScheduleName=${filters.productionScheduleName}`
        : ''
    }&${filters.recipeName ? `recipeName=${filters.recipeName}` : ''}&${
      filters.batchName ? `batchName=${filters.batchName}` : ''
    }&${
      filters.reportParameters
        ? `reportParameters=${filters.reportParameters}`
        : ''
    }&${
      filters.parameterSearchKey
        ? `parameterSearchKey=${filters.parameterSearchKey}`
        : ''
    }`;

    let body = {
      ...(data.headers ? { headers: data.headers } : {}),
      ...(data.title ? { title: data.title } : {}),
      ...(data.heading ? { heading: data.heading } : {}),
      ...(data.bodyData ? { data: data.bodyData } : {}),
      ...(data.matchKeys ? { matchKeys: data.matchKeys } : {}),
      ...(data.images ? { images: data.images } : {}),
      ...(data.imageTitles ? { imageTitles: data.imageTitles } : {})
    };

    let options = {
      method: 'POST',
      body: JSON.stringify(body)
    };

    return this.fetchNonJson(url, options)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  // get diagnostics by batchId
  batchesDiagnostics(productionScheduleId, recipeId, reportParameterIds) {
    return this.fetch(
      `${
        this.domain
      }/api/batches/getbatchesdiagnostics?productionScheduleId=${productionScheduleId}&recipeId=${recipeId}&reportParameterIds=${reportParameterIds}`,
      {
        method: 'GET'
      }
    ).then(res => {
      return Promise.resolve(res);
    });
  }

  // Download Pdf for Diagnostics Out Of Spec.
  downloadPdfForDiagOutOfSpec(filters, data) {
    let url = `${this.domain}/api/batches/getbatchesdiagnostics/generatePdf?${
      filters.lineName ? `lineName=${filters.lineName}` : ''
    }&fromDateTime=${filters.fromDateTime}&toDateTime=${filters.toDateTime}&${
      filters.productionScheduleName
        ? `productionScheduleName=${filters.productionScheduleName}`
        : ''
    }&${filters.recipeName ? `recipeName=${filters.recipeName}` : ''}&${
      filters.types ? `types=${filters.types}` : ''
    }`;

    let body = {
      ...(data.headers ? { headers: data.headers } : {}),
      ...(data.title ? { title: data.title } : {}),
      ...(data.heading ? { heading: data.heading } : {}),
      ...(data.bodyData ? { data: data.bodyData } : {}),
      ...(data.matchKeys ? { matchKeys: data.matchKeys } : {}),
      ...(data.images ? { images: data.images } : {}),
      ...(data.imageTitles ? { imageTitles: data.imageTitles } : {})
    };

    let options = {
      method: 'POST',
      body: JSON.stringify(body)
    };

    return this.fetchNonJson(url, options)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  /**
   * Gets all the batches with alarms
   * @param {*} productionScheduleId
   * @param {*} recipeId
   * @param {*} type
   */

  batchesAlarms(productionScheduleId, recipeId, types) {
    return this.fetch(
      `${
        this.domain
      }/api/batches/getbatchesalarms?productionScheduleId=${productionScheduleId}&recipeId=${recipeId}&${
        types.length ? `types=${types.join(',')}` : ''
      }`,
      {
        method: 'GET'
      }
    ).then(res => {
      return Promise.resolve(res);
    });
  }

  /**
   * get all batches and paramerts of a particular order
   * @param {string} productionScheduleId
   * @param {string} recipeId
   */
  batchesWithParameters(productionScheduleId, recipeId) {
    return this.fetch(
      `${
        this.domain
      }/api/batches/getbatcheswithparameters?productionScheduleId=${productionScheduleId}&recipeId=${recipeId}`,
      {
        method: 'GET'
      }
    ).then(res => {
      return Promise.resolve(res);
    });
  }

  /**
   * compare Report Parameters with batches
   * @param {string} productionScheduleId
   * @param {string} recipeId
   */
  compareReportparameters(batchIds, reportParameterIds) {
    return this.fetch(
      `${
        this.domain
      }/api/batches/getcomparereportparameters?batchIds=${batchIds}&reportParameterIds=${reportParameterIds}`,
      {
        method: 'GET'
      }
    ).then(res => {
      return Promise.resolve(res);
    });
  }

  // Download Pdf for Diagnostics Compare.
  downloadPdfForDiagCompare(filters, data) {
    let url = `${
      this.domain
    }/api/batches/getcomparereportparameters/generatePdf?${
      filters.lineName ? `lineName=${filters.lineName}` : ''
    }&fromDateTime=${filters.fromDateTime}&toDateTime=${filters.toDateTime}&${
      filters.productionScheduleName
        ? `productionScheduleName=${filters.productionScheduleName}`
        : ''
    }&${filters.recipeName ? `recipeName=${filters.recipeName}` : ''}&${
      filters.batchNames ? `batchNames=${filters.batchNames}` : ''
    }&${
      filters.reportParameters
        ? `reportParameters=${filters.reportParameters}`
        : ''
    }&graphMode=${filters.graphMode}`;

    let body = {
      ...(data.headers ? { headers: data.headers } : {}),
      ...(data.title ? { title: data.title } : {}),
      ...(data.heading ? { heading: data.heading } : {}),
      ...(data.bodyData ? { data: data.bodyData } : {}),
      ...(data.matchKeys ? { matchKeys: data.matchKeys } : {}),
      ...(data.images ? { images: data.images } : {}),
      ...(data.imageTitles ? { imageTitles: data.imageTitles } : {})
    };

    let options = {
      method: 'POST',
      body: JSON.stringify(body)
    };

    return this.fetchNonJson(url, options)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  // get runnig batch by lineId
  liveBatch(lineId) {
    return this.fetch(
      `${this.domain}/api/batches/getlivebatch?lineId=${lineId}`,
      {
        method: 'GET'
      }
    ).then(res => {
      return Promise.resolve(res);
    });
  }

  batchSummaryByTime(
    lineId,
    fromDateTime,
    toDateTime,
    scheduleId,
    batchId,
    recipeId,
    reportFilterType,
    page,
    limit,
    alarms,
    scheduleName
  ) {
    if (!_.isEmpty(scheduleName)) {
      return this.fetch(
        `${
          this.domain
        }/api/batches/getdetailedbatchsummery?scheduleName=${scheduleName}`,
        {
          method: 'GET'
        }
      ).then(res => {
        return Promise.resolve(res);
      });
    } else {
      let url = 'lineId=' + lineId;
      url += fromDateTime ? '&fromDateTime=' + fromDateTime : '';
      url += toDateTime ? '&toDateTime=' + toDateTime : '';
      url +=
        scheduleId && scheduleId !== '0'
          ? '&productionScheduleId=' + scheduleId
          : '';
      url += batchId && batchId !== '0' ? '&batchId=' + batchId : '';
      url += recipeId && recipeId !== '0' ? '&recipeId=' + recipeId : '';
      url +=
        reportFilterType && reportFilterType !== 'NONE'
          ? '&reportFilterType=' + reportFilterType
          : '';
      url +=
        alarms && !_.isEmpty(alarms)
          ? '&exceededParameters=' + alarms.join(',')
          : '';

      page = page ? '&page=' + page : '';
      limit = limit ? '&limit=' + limit : '';
      return this.fetch(
        `${
          this.domain
        }/api/batches/getdetailedbatchsummery${limit}${page}?${url}`,
        {
          method: 'GET'
        }
      ).then(res => {
        return Promise.resolve(res);
      });
    }
  }

  // Download Pdf for Batch Summary.
  downloadPdfForBatchSummary(filters, data) {
    let url = `${this.domain}/api/batches/getdetailedbatchsummery/generatePdf?${
      filters.lineName === 'ALL' || filters.lineName === undefined
        ? ''
        : `lineName=${filters.lineName}`
    }${
      filters.fromDateTime == undefined
        ? ''
        : `&fromDateTime=${filters.fromDateTime}`
    }${
      filters.toDateTime == undefined ? '' : `&toDateTime=${filters.toDateTime}`
    }${
      filters.recipeName == undefined ? '' : `&recipeName=${filters.recipeName}`
    }${
      filters.scheduleName == undefined
        ? ''
        : `&scheduleName=${filters.scheduleName}`
    }`;

    let body = {
      ...(data.headers ? { headers: data.headers } : {}),
      ...(data.heading ? { heading: data.heading } : {}),
      ...(data.bodyData ? { data: data.bodyData } : {}),
      ...(data.matchKeys ? { matchKeys: data.matchKeys } : {}),
      ...(data.images ? { images: data.images } : {})
    };

    let options = {
      method: 'POST',
      body: JSON.stringify(body)
    };

    return this.fetchNonJson(url, options)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  // Download Pdf for Batch Summary.
  downloadPdfForBatchSummary(filters, data) {
    let url = `${this.domain}/api/batches/getdetailedbatchsummery/generatePdf?${
      filters.lineName === 'ALL' ? '' : `lineName=${filters.lineName}`
    }&fromDateTime=${filters.fromDateTime}&toDateTime=${
      filters.toDateTime
    }&recipeName=${filters.recipeName}&scheduleName=${
      filters.scheduleName
    }&alarms=${filters.alarms}`;

    let body = {
      ...(data.headers ? { headers: data.headers } : {}),
      ...(data.heading ? { heading: data.heading } : {}),
      ...(data.bodyData ? { data: data.bodyData } : {}),
      ...(data.matchKeys ? { matchKeys: data.matchKeys } : {}),
      ...(data.images ? { images: data.images } : {})
    };

    let options = {
      method: 'POST',
      body: JSON.stringify(body)
    };

    return this.fetchNonJson(url, options)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  //batchweighingdetails
  batchWeighingDetails(batchId) {
    return this.fetch(
      `${this.domain}/api/batches/getbatchweighingdetails?batchId=${batchId}`,
      {
        method: 'GET'
      }
    ).then(res => {
      return Promise.resolve(res);
    });
  }

  //batchMixing Details
  batchMixingDetails(batchId) {
    return this.fetch(
      `${this.domain}/api/batches/getbatchmixingdetails?batchId=${batchId}`,
      {
        method: 'GET'
      }
    ).then(res => {
      return Promise.resolve(res);
    });
  }

  // Download Pdf for Batch Summary View.
  downloadPdfForBatchSummaryView(filters, data) {
    let url = `${this.domain}/api/batches/batchsummery/generatePdf?batchId=${
      filters.batchId
    }&${
      filters.weighingSearchKey
        ? `weighingSearchKey=${filters.weighingSearchKey}`
        : ''
    }&${
      filters.mixingSearchKey
        ? `mixingSearchKey=${filters.mixingSearchKey}`
        : ''
    }&${
      filters.parameterSearchKey
        ? `parameterSearchKey=${filters.parameterSearchKey}`
        : ''
    }&${
      filters.miscParameterSearchKey
        ? `miscParameterSearchKey=${filters.miscParameterSearchKey}`
        : ''
    }&recipeName=${filters.recipeName}&scheduleName=${
      filters.scheduleName
    }&shiftName=${filters.shiftName}&batchNumber=${
      filters.batchNumber
    }&setValue=${filters.setValue}&startTime=${filters.startTime}&endTime=${
      filters.endTime
    }`;

    let body = {
      ...(data.headers ? { headers: data.headers } : {}),
      ...(data.heading ? { heading: data.heading } : {}),
      ...(data.bodyData ? { data: data.bodyData } : {}),
      ...(data.matchKeys ? { matchKeys: data.matchKeys } : {}),
      ...(data.images ? { images: data.images } : {}),
      ...(data.imageTitles ? { imageTitles: data.imageTitles } : {}),
      ...(data.isTandem ? { isTandem: data.isTandem } : {})
    };

    let options = {
      method: 'POST',
      body: JSON.stringify(body)
    };

    return this.fetchNonJson(url, options)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  //Get Alarms
  getAlarms(lineId, fromDate, toDate) {
    return this.fetch(
      `${
        this.domain
      }/api/alarm/getalarms?fromDateTime=${fromDate}&toDateTime=${toDate}&lineId=${lineId}`,
      {
        method: 'GET'
      }
    ).then(res => {
      return Promise.resolve(res);
    });
  }

  //batchMixing Details
  batchParameterDetails(batchId) {
    return this.fetch(
      `${this.domain}/api/batches/getbatchparameterdetails?batchId=${batchId}`,
      {
        method: 'GET'
      }
    ).then(res => {
      return Promise.resolve(res);
    });
  }
  //batchMixing Details
  batchMiscParameterDetails(batchId) {
    return this.fetch(
      `${this.domain}/api/batches/getbatchmiscparameter?batchId=${batchId}`,
      {
        method: 'GET'
      }
    ).then(res => {
      return Promise.resolve(res);
    });
  }

  // get material codes for pcs
  getMaterialCodesForPCS() {
    return this.fetch(`${this.domain}/api/pcs/getmaterialcodes`, {
      method: 'GET'
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // get PCS report
  getPcsReport(lineId, materialCode, fromDateTime, toDateTime) {
    return this.fetch(
      `${this.domain}/api/pcs/getreport?${
        lineId && lineId !== '0' ? `lineId=${lineId}` : `lineId=''`
      }&${
        materialCode && materialCode !== 'ALL'
          ? `materialCode=${materialCode}`
          : ``
      }&fromDateTime=${fromDateTime}&toDateTime=${toDateTime}`,
      {
        method: 'GET'
      }
    ).then(res => {
      return Promise.resolve(res);
    });
  }

  // Download Pdf for Pcs Report.
  downloadPdfForPcsReport(filters, data) {
    let url = `${this.domain}/api/pcs/getreport/generatePdf?${
      filters.lineName === 'ALL' ? '' : `lineName=${filters.lineName}`
    }&fromDateTime=${filters.fromDateTime}&toDateTime=${filters.toDateTime}&${
      filters.searchKey ? `searchKey=${filters.searchKey}` : ''
    }&${filters.materialCode ? `materialCode=${filters.materialCode}` : ''}`;

    let body = {
      ...(data.headers ? { headers: data.headers } : {}),
      ...(data.heading ? { heading: data.heading } : {}),
      ...(data.bodyData ? { data: data.bodyData } : {}),
      ...(data.matchKeys ? { matchKeys: data.matchKeys } : {}),
      ...(data.images ? { images: data.images } : {}),
      ...(data.embeddedHeaders ? { embeddedHeaders: data.embeddedHeaders } : {})
    };

    let options = {
      method: 'POST',
      body: JSON.stringify(body)
    };

    return this.fetchNonJson(url, options)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  // get PCS pressure
  getPcsPressure(lineId, fromDateTime, toDateTime) {
    return this.fetch(
      `${
        this.domain
      }/api/pcs/getpressure?lineId=${lineId}&fromDateTime=${fromDateTime}&toDateTime=${toDateTime}`,
      {
        method: 'GET'
      }
    ).then(res => {
      return Promise.resolve(res);
    });
  }

  // get PCS pressure
  getPcsPressureForGraph(lineId, fromDateTime, toDateTime) {
    return this.fetch(
      `${this.domain}/api/pcs/getpressureforgraph?${
        lineId && lineId !== '0' ? `lineId=${lineId}&` : `lineId=''&`
      }fromDateTime=${fromDateTime}&toDateTime=${toDateTime}`,
      {
        method: 'GET'
      }
    ).then(res => {
      return Promise.resolve(res);
    });
  }

  //lineBatchDetails
  lineBatchDetails(batchId) {
    return this.fetch(
      `${this.domain}/api/batches/getlinebatchdetails?batchId=${batchId}`,
      {
        method: 'GET'
      }
    ).then(res => {
      return Promise.resolve(res);
    });
  }

  // list all admins
  users() {
    return this.fetch(`${this.domain}/api/user/users`, {
      method: 'GET'
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Create Admin and privilege users.
  createUser(parameterGroup) {
    parameterGroup.createdBy = this.getProfile().userEmail;
    return this.fetch(`${this.domain}/api/createuser`, {
      method: 'POST',
      body: JSON.stringify(parameterGroup)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  //  creating onpremise user
  createOnPremiseUser(parameterGroup) {
    // parameterGroup.createdBy = this.getProfile().userEmail;
    return this.fetch(`${this.domain}/api/user/onpremise/createuser`, {
      method: 'POST',
      body: JSON.stringify(parameterGroup)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // resetting onprimise user password
  resetOnPremiseUserPassword(parameterGroup) {
    return this.fetch(`${this.domain}/auth/onpremise/resetpassword`, {
      method: 'POST',
      body: JSON.stringify(parameterGroup)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Edit Admin and privilege users.
  editUser(parameterGroup) {
    parameterGroup.createdBy = this.getProfile().userEmail;
    return this.fetch(`${this.domain}/api/edituser`, {
      method: 'POST',
      body: JSON.stringify(parameterGroup)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // generate password on edit user\
  generatePasswordOnEditUser(parameterGroup) {
    return this.fetch(`${this.domain}/api/user/onpremise/generatepassword`, {
      method: 'PUT',
      body: JSON.stringify(parameterGroup)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // delete user
  deleteUser(parameterGroup) {
    return this.fetch(`${this.domain}/api/user/deleteuser`, {
      method: 'PUT',
      body: JSON.stringify(parameterGroup)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Get a particular user based on id
  getUser(id) {
    return this.fetch(`${this.domain}/api/getuser/${id}`, {
      method: 'GET'
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  downloadSampleImportFile = (type, options, progressBarEvent = null) => {
    var url = `${this.domain}/api/getsamplefile?type=${type}`;
    return this.fetchReadableStream(url, options, progressBarEvent)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  };

  validateImportParameters = options => {
    var url = `${this.domain}/api/importparameter/validate`;

    return this.fetch(url, options, true)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  };

  importParameters = data => {
    var url = `${this.domain}/api/importparameter`;

    return this.fetch(url, { method: 'POST', body: data })
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  };

  addStopTimeReasonGroups(payload) {
    payload.createdBy = this.getProfile().userEmail;
    return this.fetch(`${this.domain}/api/stoptimereasongroup/add`, {
      method: 'POST',
      body: JSON.stringify(payload)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  getAllStopTimeReasonGroups() {
    return this.fetch(`${this.domain}/api/stoptimereasongroup/`, {
      method: 'GET'
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  getStopTimeReasonGroup(id) {
    return this.fetch(`${this.domain}/api/stoptimereasongroup/${id}`, {
      method: 'GET'
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  updateStopReasonGroup(id, payload) {
    payload.updatedBy = this.getProfile().userEmail;
    return this.fetch(`${this.domain}/api/stoptimereasongroup/edit/${id}`, {
      method: 'PUT',
      body: JSON.stringify(payload)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  deleteStopReasonGroup(id) {
    return this.fetch(`${this.domain}/api/stoptimereasongroup/delete/${id}`, {
      method: 'DELETE'
    }).then(res => {
      return Promise.resolve(res);
    });
  }
  getStopTimeReport(lineId, fromDateTime, toDateTime) {
    let url = `${this.domain}/api/stoptimereport`;
    if (lineId && fromDateTime && toDateTime) {
      url += `?lineId=${lineId}&fromDateTime=${fromDateTime}&toDateTime=${toDateTime}`;
    }
    if (!lineId && fromDateTime && toDateTime) {
      url += `?fromDateTime=${fromDateTime}&toDateTime=${toDateTime}`;
    }

    return this.fetch(url, {
      method: 'GET'
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  getDashboardStopTimeSummary(body) {
    let url = `${this.domain}/api/dashboard/stoptimelinesummary`;
    return this.fetch(url, {
      method: 'POST',
      body: JSON.stringify(body)
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // fetch function.
  fetch(url, options, isMultipartData = false) {
    // performs api calls sending the required authentication headers
    let headers = new Headers();
    let locale = JSON.parse(localStorage.getItem('locale'));

    if (isMultipartData) {
      headers = {
        'Accept-language': locale
      };
    } else {
      headers = {
        'Content-Type': options['Content-Type']
          ? options['Content-Type']
          : 'application/json',
        'Accept-language': locale
      };
    }

    // Setting Authorization header
    // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
    if (this.loggedIn()) {
      headers['Authorization'] = 'bearer ' + this.getToken();
    }

    return fetch(url, {
      headers,
      ...options
    })
      .then(this._checkStatus.bind(this))
      .then(response => {
        return response.json();
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  //Fetch response as non json format especially required for generating pdf,text,doc etc
  fetchNonJson = (url, options) => {
    let headers = new Headers();
    let locale = JSON.parse(localStorage.getItem('locale'));
    headers = {
      'Content-Type': 'application/json',
      'Accept-language': locale
    };

    // Setting Authorization header
    // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
    if (this.loggedIn()) {
      headers['Authorization'] = 'bearer ' + this.getToken();
    }

    return fetch(url, {
      headers,
      ...options
    })
      .then(this._checkStatus.bind(this))
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  };

  fetchReadableStream = async (url, options, progressBarEvent = null) => {
    let headers = new Headers();
    let locale = JSON.parse(localStorage.getItem('locale'));
    headers = {
      'Content-Type': 'application/json',
      'Accept-language': locale
    };

    // Setting Authorization header
    // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
    if (this.loggedIn()) {
      headers['Authorization'] = 'bearer ' + this.getToken();
    }

    try {
      let response = await fetch(url, {
        headers,
        ...options
      });
      response = this._checkStatus(response);

      const reader = response.body.getReader();
      // Step 2: get total length
      const contentLength = +response.headers.get('Content-Length');

      // Step 3: read the data
      let receivedLength = 0; // received that many bytes at the moment
      let chunks = []; // array of received binary chunks (comprises the body)
      let previousValue = 0;
      let progressPercentage = 0;
      let oldPerc = progressPercentage;
      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          if (progressBarEvent) {
            progressBarEvent(100);
          }
          let blob = new Blob(chunks, {
            type: response.headers.get('Content-Type')
          });
          var temURL = window.URL.createObjectURL(blob);
          var tempLink = document.createElement('a');
          tempLink.href = temURL;
          var fileName = response.headers
            .get('Content-Disposition')
            .split('filename=')[1];
          tempLink.setAttribute('download', fileName);
          tempLink.click();
          return Promise.resolve();

          break;
        }

        chunks.push(value);
        receivedLength += value.length;
        if (progressBarEvent && progressPercentage < 100) {
          progressPercentage = Math.floor(
            (previousValue / receivedLength) * 100
          );
          if (oldPerc != progressPercentage) {
            progressBarEvent(progressPercentage);
          } else {
            oldPerc = progressPercentage;
          }

          previousValue += receivedLength;
        }
        console.log(`Received ${receivedLength} of ${contentLength}`);
      }
    } catch (error) {
      throw error;
    }
  };

  // Get profile
  getProfile() {
    // Using jwt-decode npm package to decode the token
    return decode(this.getToken());
  }

  // Check Status
  _checkStatus(response) {
    // raises an error in case response status is not a success
    if (response.status == 401) {
      this.logout();
      window.location = '/home';
    } else if (response.status >= 200 && response.status < 300) {
      return response;
    } else {
      var error = new Error(response.statusText); // TODO: Implement 404 !!
      error.response = response;
      return error;
    }
  }

  // Set token
  setToken(idToken) {
    // Saves user token to localStorage
    localStorage.setItem('id_token', idToken);
  }

  // Get token from local storage.
  getToken() {
    // Retrieves the user token from localStorage
    return localStorage.getItem('id_token');
  }

  // Logout  TODO: handle client side aswell
  logout() {
    // Clear user token and profile data from localStorage
    this.removeToken('id_token'); // Setting the token in localStorage
    return this.fetch(`${this.domain}/auth/logout`, {
      method: 'GET'
    }).then(res => {
      return Promise.resolve(res);
    });
  }

  // Get token from local storage.
  removeToken() {
    // Retrieves the user token from localStorage
    return localStorage.removeItem('id_token');
  }

  // Token expired status.
  isTokenExpired(token) {
    try {
      const decoded = decode(token);
      if (decoded.exp < Date.now() / 1000) {
        // Checking if token is expired. N
        return true;
      } else return false;
    } catch (err) {
      return false;
    }
  }

  // Logged in status
  loggedIn() {
    // Checks if there is a saved token and it's still valid
    const token = this.getToken(); // Getting token from localstorage
    return !!token && !this.isTokenExpired(token); // handwaiving here
  }

  render() {
    return (
      <Router>
        <Switch>
          <Route path="/Home" component={Home} />
          <Route path="/login" component={Login} />
          <Route path="/superuserlogin" component={SuperUserLogin} />
          <Route path="/resetpassword/:token" component={ResetPassword} />
          <Route
            path="/resetonprimiseuserpassword"
            component={ResetOnPrimiseUserPassword}
          />
          <Route path="/forgotpassword" component={ForgotPassword} />
          <Route path="/validatesignup/:token" component={ValidateSignup} />
          <PrivateRoute path="/" component={Application} />
        </Switch>
      </Router>
    );
  }
}

// Logged in status
function loggedIn() {
  // Checks if there is a saved token and it's still valid
  const token = getToken(); // Getting token from localstorage
  if (!!token && !isTokenExpired(token)) {
    return true;
  } else {
    return false;
  }
}

// Token expired status.
function isTokenExpired(token) {
  try {
    const decoded = decode(token);
    if (decoded.exp < Date.now() / 1000) {
      // Checking if token is expired. N
      return true;
    } else return false;
  } catch (err) {
    return false;
  }
}

// Get token from local storage.
function getToken() {
  // Retrieves the user token from localStorage
  return localStorage.getItem('id_token');
}

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      loggedIn() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/Home',
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

export default AuthService;
