import React, { Component } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Label,
  Button,
  CardFooter,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  FormGroup
} from 'reactstrap';
import TableComponent from '../../components/Table/Table';
import I18nMessage from '../../components/i18n/I18nMessage';
import { FormattedMessage } from 'react-intl';
import { withAlert } from 'react-alert';
import AuthService from '../../components/AuthService/authentication';
import Loader from '../../components/UtilitiesService/Loader';
import config from '../../utils/config';
import _ from 'lodash';

class BinAssignment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lines: [],
      bins: [],
      scales: [],
      materialDirectory: [],
      binStatus: ['enabled', 'disabled'],
      lineId: '0',
      scaleId: '0',
      edited: false,
      search: '',
      loading: true
    };

    this.Auth = new AuthService();
    this.getScales = this.getScales.bind(this);
  }

  handleChange(id, e) {
    let _index = 0;
    while (this.state.bins[_index]._id !== id) {
      _index++;
    }
    var anotherRow = {};
    anotherRow = this.state.bins;
    if (e.target.name === 'materialDirectoryId') {
      anotherRow[_index].MaterialDirectoryId =
        e.target.value === '0' ? null : e.target.value;
      anotherRow[_index].status = this.state.binStatus[1];
      anotherRow[_index].isEdited = true;
    } else if (e.target.name === 'status') {
      anotherRow[_index].status =
        e.target.value === '0' ? null : e.target.value;
      anotherRow[_index].isEdited = true;
    }
    this.setState({
      bins: anotherRow,
      edited: true
    });
  }

  submitBinAssignment(e) {
    e.preventDefault();
    var updatedBins = _.filter(this.state.bins, obj => {
      return obj.isEdited === true;
    });
    this.Auth.addMaterial(updatedBins)
      .then(res => {
        if (res.success) {
          _.forEach(this.state.bins, function(item) {
            if (item.isEdited) {
              item.isEdited = false;
            }
          });
          this.props.history.push('/recipemanagement/binassignment');
          this.props.alert.success(res.message);
        } else {
          this.props.alert.error(res.message);
        }
      })
      .catch(err => {
        this.props.alert.error(
          <I18nMessage id="Error Assigning Bin to Material" />
        );
      });
  }

  componentDidMount() {
    // Get All Scales Data.
    this.getScales();

    // Get all Bines with data.
    this.Auth.binsWithScale()
      .then(res => {
        this.setState({ loading: false });
        if (res.success) {
          this.setState({
            bins: res.binsWithScale
          });
        }
        // Else Show Error
      })
      .catch(err => {
        this.setState({ loading: false });
        this.props.alert.error(<I18nMessage id="Error Getting bin" />);
      });

    // Get all lines listed.
    this.Auth.lines()
      .then(res => {
        this.setState({ loading: false });
        if (res.success) {
          this.setState({
            lines: res.lines
          });
        }
        // Else Show Error
        else {
          this.props.alert.error(res.message);
        }
      })
      .catch(err => {
        this.setState({ loading: false });
        this.props.alert.error(
          <I18nMessage id="Something went wrong Please try again after some time" />
        );
      });
  }

  // Get scales for a particular Line.
  getScales(lineId) {
    this.setState({ loading: true });
    this.Auth.scales(lineId)
      .then(res => {
        this.setState({ loading: false });
        if (res.success) {
          this.setState({
            scales: res.scales
          });
        }
        // Else Show Error
      })
      .catch(err => {
        this.setState({ loading: false });
        this.props.alert.error(<I18nMessage id="Error Getting scales" />);
      });
  }

  render() {
    // Table Data.
    let data = this.state.bins;
    const columns = [
      {
        Header: <I18nMessage id="Bin No" />,
        id: 'Bin No',
        accessor: 'binNumber'
      },
      {
        Header: <I18nMessage id="Bin Name" />,
        id: 'Bin Name',
        accessor: 'binName'
      },
      {
        Header: <I18nMessage id="Material" />,
        id: 'Material',
        accessor: 'materialdata',
        Cell: props => (
          <span>
            {props.original.materialdata.length > 0 ? (
              <Input
                type="select"
                name="materialDirectoryId"
                id="select"
                value={
                  props.original.MaterialDirectoryId === null
                    ? '0'
                    : props.original.MaterialDirectoryId
                }
                onChange={this.handleChange.bind(this, props.original._id)}
                disabled={
                  props.original.lowLevelInterlock === true &&
                  props.original.lowLevelSignal === false
                }
              >
                <FormattedMessage
                  id={'Select Material'}
                  key={'Select Material'}
                >
                  {() => <option value={0}>Select Material</option>}
                </FormattedMessage>
                {props.original.materialdata.map(j => {
                  return (
                    <option key={j} value={j._id}>
                      {j.materialName}
                    </option>
                  );
                })}
              </Input>
            ) : (
              <span>
                <I18nMessage id="Please assign a Dosing Point" />
              </span>
            )}
          </span>
        ),
        filterable: false,
        sortable: false
      },
      {
        Header: <I18nMessage id="Status" />,
        id: 'Status',
        accessor: 'status',
        Cell: props => (
          <span>
            {props.original.materialdata.length > 0 ? (
              <Input
                type="select"
                name="status"
                id="select"
                disabled={
                  props.original.materialdata.length < 1 ||
                  props.original.MaterialDirectoryId === null
                }
                style={{
                  color:
                    props.original.status === 'disabled' ? '#CD5C5C' : '#2E8B57'
                }}
                value={
                  props.original.status === null ? '0' : props.original.status
                }
                onChange={this.handleChange.bind(this, props.original._id)}
              >
                {props.original.MaterialDirectoryId === '0' ? (
                  <span>
                    <I18nMessage id="Please select a material" />
                  </span>
                ) : (
                  this.state.binStatus.map(j => {
                    return (
                      <option key={j} value={j}>
                        {j}
                      </option>
                    );
                  })
                )}
              </Input>
            ) : (
              <span>
                <I18nMessage id="Please assign a material" />
              </span>
            )}
          </span>
        ),
        filterable: false,
        sortable: false
      }
    ];

    if (this.state.search) {
      data = data.filter(row => {
        return row.binName.includes(this.state.search);
      });
    }

    //scale filter
    if (this.state.scaleId !== '0') {
      data = data.filter(row => {
        return row.scaledata.length > 0;
      });

      data = data.filter(row => {
        return row.scaledata[0]._id === this.state.scaleId;
      });
    }

    // line filter
    if (this.state.lineId !== '0') {
      data = data.filter(row => {
        return row.lineId == this.state.lineId;
      });
    }

    return (
      <div className="animated fadeIn">
        <Row>
          <Col xs="12" lg="12">
            <Card>
              <CardHeader>
                <strong>
                  <I18nMessage id="Bin Assignment" />
                </strong>
              </CardHeader>
              <CardBody>
                <FormGroup row>
                  <Col xs="12" sm="4" md="4">
                    <small htmlFor="email-input">
                      <I18nMessage id="Search" />
                    </small>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fa fa-search" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        type="text"
                        name="search"
                        value={this.state.search}
                        onChange={e =>
                          this.setState({ search: e.target.value })
                        }
                        placeholder="Bin Name"
                      />
                    </InputGroup>
                  </Col>
                  <Col xs="6" md="4">
                    <small htmlFor="email-input">
                      <I18nMessage id="Lines" />
                    </small>
                    <Input
                      type="select"
                      name="lineId"
                      id="select"
                      value={this.state.lineId}
                      onChange={e =>
                        this.setState(
                          {
                            lineId: e.target.value,
                            scaleId: '0',
                            search: ''
                          },
                          function() {
                            // Get sclaes particular to this Line Id
                            if (this.state.lineId !== '0') {
                              this.getScales(this.state.lineId);
                            } else if (this.state.lineId === '0') {
                              this.getScales();
                            }
                          }
                        )
                      }
                    >
                      <FormattedMessage id={'All Lines'} key={'All Lines'}>
                        {() => <option value={0}>All Lines</option>}
                      </FormattedMessage>
                      {this.state.lines.map(i => {
                        return (
                          <option key={i} value={i._id}>
                            {i.lineName}
                          </option>
                        );
                      })}
                    </Input>
                  </Col>
                  <Col xs="12" md="4">
                    <small htmlFor="email-input">
                      <I18nMessage id="Scales" />
                    </small>
                    <Input
                      type="select"
                      name="scaleId"
                      id="select"
                      value={this.state.scaleId}
                      onChange={e => this.setState({ scaleId: e.target.value })}
                    >
                      <FormattedMessage id={'All Scales'} key={'All Scales'}>
                        {() => <option value={0}>All Scales</option>}
                      </FormattedMessage>
                      {this.state.scales.map(i => {
                        return (
                          <option key={i} value={i._id}>
                            {i.scaleName}
                          </option>
                        );
                      })}
                    </Input>
                  </Col>
                </FormGroup>
                {this.state.loading ? (
                  <Loader loading={this.state.loading} />
                ) : (
                  <TableComponent
                    data={data}
                    columns={columns}
                    sortable={true}
                    loading={this.state.loading}
                    showPaginationBottom={config.showPaginationBottom}
                    pageSize={
                      data.length <= 1
                        ? 5
                        : data.length < 50
                        ? data.length
                        : config.pageSize
                    }
                    showPageSizeOptions={false}
                    className="-striped -highlight"
                    showPageJump={false}
                    bodyStyles={{
                      'overflow-x': 'hidden',
                      'overflow-y': 'scroll',
                      height: '550px'
                    }}
                    tableStyles={{}}
                  />
                )}
              </CardBody>
              <CardFooter>
                <div className="card-header-actions">
                  <Col col="6" sm="4" md="2" xl className="mb-3 mb-xl-0">
                    <Button
                      block
                      outline
                      color="success"
                      aria-pressed="true"
                      onClick={this.submitBinAssignment.bind(this)}
                      disabled={!this.state.edited}
                    >
                      <I18nMessage id="Save" />
                    </Button>
                  </Col>
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}
export default withAlert(BinAssignment);
