import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import ReactTable from 'react-table';
import * as _ from 'lodash';

class TableComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bodyStyles: props.bodyStyles,
      tableStyles: props.tableStyles,
      showSrNoCol: props.showSrNoCol == undefined ? true : props.showSrNoCol
    };
    this.reference = props.ref || React.createRef();
  }

  componentDidMount = () => {
    this.element = ReactDOM.findDOMNode(this.reference.current);
    this.tableInit();
  };

  componentDidUpdate = () => {
    this.element = ReactDOM.findDOMNode(this.reference.current);
    this.tableInit();
  };

  tableInit = () => {
    const tableElem = this.element.getElementsByClassName('rt-table')[0];
    const bodyElem = this.element.getElementsByClassName('rt-tbody')[0];

    bodyElem &&
      bodyElem.addEventListener('wheel', e => {
        if (e.deltaX !== 0) {
          tableElem.scrollBy(e.deltaX, 0);
        }
      });

    if (this.reference.current.state.pageSize > 15) {
      //To not apply styles
      if (!_.isEmpty(this.state.bodyStyles)) {
        Object.keys(this.state.bodyStyles).map(key => {
          bodyElem.style[key] = this.state.bodyStyles[key];
        });
      }

      if (!_.isEmpty(this.state.tableStyles)) {
        Object.keys(this.state.tableStyles).map(key => {
          tableElem.style[key] = this.state.tableStyles[key];
        });
      }
    }
  };

  render() {
    let addFooter;
    if (this.props.addFooter) {
      addFooter = this.props.data.length > 0 ? <b>Total</b> : null;
    }

    if (
      this.state.showSrNoCol &&
      !_.find(this.props.columns, { Header: 'Sr No' })
    ) {
      this.props.columns.unshift({
        Header: 'Sr No',
        id: 'SrNo',
        Cell: row => {
          return <div>{row.index + 1}</div>;
        },
        Footer: addFooter
      });
    }
    return (
      <ReactTable
        ref={this.reference}
        columns={this.props.columns}
        {...this.props}
      />
    );
  }
}

export default TableComponent;
