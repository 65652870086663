/**
 * Socket channel names
 */
export const GET_LINE_STATUS_SOCKET = 'getLineStatusSocket';
export const GET_LINE_STATUS_RESPONSE_SOCKET = 'lineStatusResponseSocket';
export const GET_BATCH_DIAGNOSTICS_SOCKET = 'diagnostics';
export const ERROR_SOCKET = 'errorResponseSocket';

//Local Storage Keys
export const CONFIG_UI_LOCAL_STORAGE = 'configUI';

//ConfigUI Key set in lines model
export const CONFIG_UI_KEY = 'configUI';

//ConfigUI Paths
export const ConfigUI_Paths = {
  recipeWeighing: 'configUI.recipeConfig.weighing',
  iRam: 'configUI.iramConfig'
};

//IRam keys
export const PRESSURE_IRAM = 'PRESSURE';
export const POSITION_IRAM = 'POSITION';
export const BOTH_IRAM = 'BOTH';
