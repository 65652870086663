import React from 'react';
import Faq from '../../views/Faq';
import I18nMessage from '../i18n/I18nMessage';
import Application from './Application';

// FAQ
const FAQ = React.lazy(() => import('../../views/Faq'));

const AddNewFaq = React.lazy(() => import('../../views/Faq/AddFaq'));

const ViewFaq = React.lazy(() => import('../../views/Faq/ViewFaq'));

const EditFaq = React.lazy(() => import('../../views/Faq/EditFaq'));

// Dashboard
const Dashboard = React.lazy(() => import('../../views/Dashboard/Dashboard'));

// Lines
const Line = React.lazy(() => import('../../views/Configurator/Lines/index'));

const AddNewLine = React.lazy(() =>
  import('../../views/Configurator/Lines/AddNewLine')
);

const EditLine = React.lazy(() =>
  import('../../views/Configurator/Lines/EditLine')
);

const ViewLine = React.lazy(() =>
  import('../../views/Configurator/Lines/ViewLine')
);

// Shift
const Shift = React.lazy(() => import('../../views/Configurator/Shifts/index'));

const AddShift = React.lazy(() =>
  import('../../views/Configurator/Shifts/AddShift')
);

const EditShift = React.lazy(() =>
  import('../../views/Configurator/Shifts/EditShift')
);

// Material Categories.
const MaterialCategories = React.lazy(() =>
  import('../../views/Configurator/MaterialCategories/index')
);

const AddMaterialCategory = React.lazy(() =>
  import('../../views/Configurator/MaterialCategories/AddMaterialCategory')
);

const EditMaterialCategory = React.lazy(() =>
  import('../../views/Configurator/MaterialCategories/EditMaterialCategory')
);

const ViewMaterialCategory = React.lazy(() =>
  import('../../views/Configurator/MaterialCategories/ViewMaterialCategory')
);

// -- Scales.
const Scales = React.lazy(() =>
  import('../../views/Configurator/DosingAndFeeding/Scales/')
);

const AddScale = React.lazy(() =>
  import('../../views/Configurator/DosingAndFeeding/Scales/AddScale')
);

const EditScale = React.lazy(() =>
  import('../../views/Configurator/DosingAndFeeding/Scales/EditScale')
);

const ViewScale = React.lazy(() =>
  import('../../views/Configurator/DosingAndFeeding/Scales/ViewScale')
);

// -- Bins.
const Bins = React.lazy(() =>
  import('../../views/Configurator/DosingAndFeeding/Bins/')
);

const AddBin = React.lazy(() =>
  import('../../views/Configurator/DosingAndFeeding/Bins/AddBin')
);

const EditBin = React.lazy(() =>
  import('../../views/Configurator/DosingAndFeeding/Bins/EditBin')
);

const ViewBin = React.lazy(() =>
  import('../../views/Configurator/DosingAndFeeding/Bins/ViewBin')
);

// -- Dosing Points.
const DosingPoints = React.lazy(() =>
  import('../../views/Configurator/DosingAndFeeding/DosingPoints/')
);

const AddDosingPoint = React.lazy(() =>
  import(
    '../../views/Configurator/DosingAndFeeding/DosingPoints/AddDosingPoint'
  )
);

const EditDosingPoint = React.lazy(() =>
  import(
    '../../views/Configurator/DosingAndFeeding/DosingPoints/EditDosingPoint'
  )
);

const ViewDosingPoint = React.lazy(() =>
  import(
    '../../views/Configurator/DosingAndFeeding/DosingPoints/ViewDosingPoint'
  )
);

// -- Feeding Points.
const FeedingPoints = React.lazy(() =>
  import('../../views/Configurator/DosingAndFeeding/FeedingPoints/')
);

const AddFeedingPoint = React.lazy(() =>
  import(
    '../../views/Configurator/DosingAndFeeding/FeedingPoints/AddFeedingPoint'
  )
);

const EditFeedingPoint = React.lazy(() =>
  import(
    '../../views/Configurator/DosingAndFeeding/FeedingPoints/EditFeedingPoint'
  )
);

const ViewFeedingPoint = React.lazy(() =>
  import(
    '../../views/Configurator/DosingAndFeeding/FeedingPoints/ViewFeedingPoint'
  )
);

// -- Mixing Action Parameter
const ActionParameter = React.lazy(() =>
  import('../../views/Configurator/MixerMode/ActionParameter/')
);

const AddActionParameters = React.lazy(() =>
  import(
    '../../views/Configurator/MixerMode/ActionParameter/AddActionParameters'
  )
);

const EditActionParameters = React.lazy(() =>
  import(
    '../../views/Configurator/MixerMode/ActionParameter/EditActionParameters'
  )
);

const ViewActionParameters = React.lazy(() =>
  import(
    '../../views/Configurator/MixerMode/ActionParameter/ViewActionParameters'
  )
);

// -- Mixing Mode Parameter.

const ModeParameter = React.lazy(() =>
  import('../../views/Configurator/MixerMode/ModeParameter/')
);

const EditModeParameters = React.lazy(() =>
  import('../../views/Configurator/MixerMode/ModeParameter/EditModeParameters')
);

const ViewModeParameters = React.lazy(() =>
  import('../../views/Configurator/MixerMode/ModeParameter/ViewModeParameters')
);

const AddModeParameters = React.lazy(() =>
  import('../../views/Configurator/MixerMode/ModeParameter/AddModeParameters')
);

// IRam
const AddNewIram = React.lazy(() =>
  import('../../views/Configurator/MixerMode/Iram/AddNewIram')
);

// Edit IRam
const EditIram = React.lazy(() =>
  import('../../views/Configurator/MixerMode/Iram/EditIram')
);

const Iram = React.lazy(() =>
  import('../../views/Configurator/MixerMode/Iram/index')
);

const ViewIram = React.lazy(() =>
  import('../../views/Configurator/MixerMode/Iram/ViewIram')
);

// -- Mixing Action
const MixingAction = React.lazy(() =>
  import('../../views/Configurator/MixerMode/MixingAction/')
);

const EditMixingAction = React.lazy(() =>
  import('../../views/Configurator/MixerMode/MixingAction/EditMixingAction')
);

const ViewMixingAction = React.lazy(() =>
  import('../../views/Configurator/MixerMode/MixingAction/ViewMixingAction')
);

const NewMixingAction = React.lazy(() =>
  import('../../views/Configurator/MixerMode/MixingAction/AddMixingAction')
);

// -- Mixing Mode
const MixingMode = React.lazy(() =>
  import('../../views/Configurator/MixerMode/MixingMode/')
);

const AddMixingMode = React.lazy(() =>
  import('../../views/Configurator/MixerMode/MixingMode/AddMixingMode')
);

const EditMixingMode = React.lazy(() =>
  import('../../views/Configurator/MixerMode/MixingMode/EditMixingMode')
);

const ViewMixingMode = React.lazy(() =>
  import('../../views/Configurator/MixerMode/MixingMode/ViewMixingMode')
);

// Miscellaneous Parameters
const MiscellaneousParams = React.lazy(() =>
  import('../../views/Configurator/MiscellaneousParameters')
);

// Miscellaneous Parameters edit
const EditMiscellaneousParams = React.lazy(() =>
  import(
    '../../views/Configurator/MiscellaneousParameters/EditMiscellaneousParameters'
  )
);

// View Miscellaneous Params
const ViewMiscellaneousParams = React.lazy(() =>
  import(
    '../../views/Configurator/MiscellaneousParameters/ViewMiscellaneousParameters'
  )
);

const AddMiscellaneousParameters = React.lazy(() =>
  import(
    '../../views/Configurator/MiscellaneousParameters/AddMiscellaneousParameters'
  )
);

// Report Parameters
const ReportParams = React.lazy(() =>
  import('../../views/Configurator/ReportParameters')
);

// Add Report Parameters
const AddReportParameters = React.lazy(() =>
  import('../../views/Configurator/ReportParameters/AddReportParameters')
);

// Edit Report Parameters
const EditReportParameters = React.lazy(() =>
  import('../../views/Configurator/ReportParameters/EditReportParameters')
);

// View Report Parameters
const ViewReportParameters = React.lazy(() =>
  import('../../views/Configurator/ReportParameters/ViewReportParameters')
);

// Engineering Parameters
const EngineeringParams = React.lazy(() =>
  import('../../views/Configurator/EngineeringParameters')
);

// Material Directory
const MaterialDirectory = React.lazy(() =>
  import('../../views/RecipeManagement/MaterialDirectory/index')
);

const ViewMaterialDirectory = React.lazy(() =>
  import('../../views/RecipeManagement/MaterialDirectory/ViewMaterialDirectory')
);

const AddMaterialDirectory = React.lazy(() =>
  import('../../views/RecipeManagement/MaterialDirectory/AddMaterialDirectory')
);

const EditMaterialDirectory = React.lazy(() =>
  import('../../views/RecipeManagement/MaterialDirectory/EditMaterialDirectory')
);

const BinAssignment = React.lazy(() =>
  import('../../views/RecipeManagement/BinAssignment')
);

const AddEngineeringParameters = React.lazy(() =>
  import(
    '../../views/Configurator/EngineeringParameters/AddEngineeringParameters'
  )
);

const EditEngineeringParameters = React.lazy(() =>
  import(
    '../../views/Configurator/EngineeringParameters/EditEngineeringParameters'
  )
);

const ViewEngineeringParameters = React.lazy(() =>
  import(
    '../../views/Configurator/EngineeringParameters/ViewEngineeringParameters'
  )
);

//Stop Time Views

const StopTimeReasons = React.lazy(() =>
  import('../../views/Configurator/StopTime/StopTimeReasons')
);

const ViewStopTimeReasons = React.lazy(() =>
  import(
    '../../views/Configurator/StopTime/StopTimeReasons/ViewStopTimeReasons'
  )
);

const AddStopTimeReasons = React.lazy(() =>
  import('../../views/Configurator/StopTime/StopTimeReasons/AddStopTimeReasons')
);

const EditStopTimeReasons = React.lazy(() =>
  import(
    '../../views/Configurator/StopTime/StopTimeReasons/EditStopTimeReasons'
  )
);

const Recipes = React.lazy(() => import('../../views/Recipes/'));

const AddRecipe = React.lazy(() => import('../../views/Recipes/AddRecipe'));

const EditRecipe = React.lazy(() => import('../../views/Recipes/EditRecipe'));

const ViewRecipe = React.lazy(() => import('../../views/Recipes/ViewRecipe'));

const SaveAsRecipe = React.lazy(() =>
  import('../../views/Recipes/SaveAsRecipe')
);

const AddProductionSchedule = React.lazy(() =>
  import('../../views/Scheduling/ProductionSchedules/AddProductionSchedule')
);

const EditProductionSchedule = React.lazy(() =>
  import('../../views/Scheduling/ProductionSchedules/EditProductionSchedule')
);

const ProductionSchedules = React.lazy(() =>
  import('../../views/Scheduling/ProductionSchedules')
);

const ViewProductionSchedule = React.lazy(() =>
  import('../../views/Scheduling/ProductionSchedules/ViewProductionSchedule')
);

const ByMaterials = React.lazy(() =>
  import('../../views/Reporting/Consumption/ByMaterials')
);

const ConsumptionStatistics = React.lazy(() =>
  import('../../views/Reporting/Consumption/ConsumptionStatistics')
);

const ByMaterial = React.lazy(() =>
  import('../../views/Reporting/Consumption/ByMaterial')
);

const ByBatches = React.lazy(() =>
  import('../../views/Reporting/Consumption/ByBatches')
);

const ByMaterialCategory = React.lazy(() =>
  import('../../views/Reporting/Consumption/ByMaterialCategory')
);

const ProductionByRecipe = React.lazy(() =>
  import('../../views/Reporting/Production/ProductionByRecipe')
);

const ProductionByTime = React.lazy(() =>
  import('../../views/Reporting/Production/ProductionByTime')
);

const LineDetails = React.lazy(() =>
  import('../../views/Reporting/Production/linedetails')
);

const BatchSummary = React.lazy(() =>
  import('../../views/Reporting/Batch/BatchSummary')
);

const BatchDetails = React.lazy(() =>
  import('../../views/Reporting/Batch/BatchDetails')
);

const AlarmDetails = React.lazy(() =>
  import('../../views/Reporting/Batch/AlarmDetails')
);

const DiagnosticsParameters = React.lazy(() =>
  import('../../views/Reporting/Diagnostics/DiagnosticsParameters')
);

const DownstreamParameters = React.lazy(() =>
  import('../../views/Reporting/Diagnostics/DownstreamParameters')
);

const Realtime = React.lazy(() =>
  import('../../views/Reporting/Diagnostics/Realtime')
);

const OutOfSpec = React.lazy(() =>
  import('../../views/Reporting/Diagnostics/OutOfSpec')
);

const CompareReportparameters = React.lazy(() =>
  import('../../views/Reporting/Diagnostics/CompareReportparameters')
);

const Users = React.lazy(() => import('../../views/UserManagement/Users'));

const CreateUser = React.lazy(() =>
  import('../../views/UserManagement/CreateUser')
);

const EditUser = React.lazy(() =>
  import('../../views/UserManagement/EditUser')
);

const CopyParameterRouter = React.lazy(() =>
  import('../../views/Utils/CopyParams')
);

const pcsReport = React.lazy(() => import('../../views/Reporting/Pcs/report'));

const pcsPressure = React.lazy(() =>
  import('../../views/Reporting/Pcs/pressure')
);

const consumptionStatisticsOrderWiseGraphRoute = React.lazy(() =>
  import('../../views/Reporting/Consumption/ConsumptionStatisticGraph')
);

// Bin history
const BinHistory = React.lazy(() =>
  import('../../views/Reporting/Others/BinHistory/binHistory')
);

const RecipeReport = React.lazy(() =>
  import('../../views/Reporting/Others/RecipeReport/recipeReport')
);

const ShiftReport = React.lazy(() =>
  import('../../views/Reporting/Others/ShiftReport/shiftReport')
);

const ShiftWiseReport = React.lazy(() =>
  import('../../views/Reporting/Others/ShiftWiseReport/shiftWiseReport')
);

const MaterialProductionReport = React.lazy(() =>
  import(
    '../../views/Reporting/Others/MaterialProductionReport/MaterialProductionReport'
  )
);

const MaterialConsumptionReport = React.lazy(() =>
  import(
    '../../views/Reporting/Others/MaterialConsumptionReport/materialConsumptionReport'
  )
);

const MixerKWReport = React.lazy(() =>
  import('../../views/Reporting/Others/MixerKWReport/mixerKWReport')
);

const BatchReport = React.lazy(() =>
  import('../../views/Reporting/Others/BatchReport/batchReport')
);

const LotReport = React.lazy(() =>
  import('../../views/Reporting/Others/LotReport/lotReport')
);

const StopTimeReport = React.lazy(() =>
  import('../../views/Reporting/Others/stopTimeReport')
);

const importRoute = React.lazy(() => import('../../views/Import/import'));

const StopTimeGroupRoutes = React.lazy(() =>
  import('../../views/Configurator/StopTime/stopTimeGroup')
);
const AddStopTimeGroupRoutes = React.lazy(() =>
  import('../../views/Configurator/StopTime/stopTimeGroup/AddGroup')
);
const EditStopTimeGroupRoutes = React.lazy(() =>
  import('../../views/Configurator/StopTime/stopTimeGroup/EditGroup')
);
const ViewStopTimeGroupRoutes = React.lazy(() =>
  import('../../views/Configurator/StopTime/stopTimeGroup/ViewGroup')
);
// TODO: Create an unauthorized component
// const Unauthorized = Loadable({
//   loader: () => import('../../views/UserManagement/CreateUser'),
//   loading: Loading
// });

// Routes for a super user.
const routes = [
  {
    path: '/',
    exact: true,
    name: <I18nMessage id="Home" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: Application
  },
  {
    path: '/faq',
    exact: true,
    name: <I18nMessage id="FAQ" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: FAQ
  },
  {
    path: '/faq/new',
    name: <I18nMessage id="New" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: AddNewFaq
  },
  {
    path: '/faq/view/:id',
    name: <I18nMessage id="View" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: ViewFaq
  },
  {
    path: '/faq/edit/:id',
    name: <I18nMessage id="Edit" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: EditFaq
  },
  {
    path: '/dashboard',
    name: <I18nMessage id="Dashboard" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: Dashboard
  },
  {
    path: '/configurator',
    exact: true,
    name: <I18nMessage id="Configurator" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE']
  },
  {
    path: '/configurator/line',
    exact: true,
    name: <I18nMessage id="Line" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: Line
  },
  {
    path: '/configurator/Shift',
    exact: true,
    name: <I18nMessage id="Shift" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: Shift
  },

  {
    path: '/configurator/shift/add',
    exact: true,
    name: <I18nMessage id="AddShift" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: AddShift
  },

  {
    path: '/configurator/shift/edit',
    exact: true,
    name: <I18nMessage id="EditShift" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: EditShift
  },

  {
    path: '/configurator/line/new',
    name: <I18nMessage id="New" />,
    privileges: ['_LINE_CREATE'],
    role: ['_SUPER'],
    component: AddNewLine
  },
  {
    path: '/configurator/line/view/:id',
    name: <I18nMessage id="View" />,
    privileges: ['_LINE_UPDATE'],
    role: ['_SUPER', 'ADMIN', 'PREVILAGE'],
    component: ViewLine
  },
  {
    path: '/configurator/line/edit/:id',
    name: <I18nMessage id="Edit" />,
    privileges: ['_LINE_UPDATE'],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: EditLine
  },
  {
    path: '/configurator/materialcategories',
    exact: true,
    name: <I18nMessage id="Material Categories" />,
    privileges: ['CONFIGURATOR_READ'],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: MaterialCategories
  },
  {
    path: '/configurator/materialcategories/new',
    name: <I18nMessage id="New" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: AddMaterialCategory
  },
  {
    path: '/configurator/materialcategories/editmaterialcategory/:id',
    name: <I18nMessage id="Edit" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: EditMaterialCategory
  },
  {
    path: '/configurator/materialcategories/viewmaterialcategory/:id',
    name: <I18nMessage id="View" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: ViewMaterialCategory
  },

  {
    path: '/configurator/dosingandfeeding',
    exact: true,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    name: <I18nMessage id="Dosing and Feeding" />
  },
  {
    path: '/configurator/dosingandfeeding/scales',
    exact: true,
    name: <I18nMessage id="Scales" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: Scales
  },
  {
    path: '/configurator/dosingandfeeding/scales/new',
    name: <I18nMessage id="New" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: AddScale
  },
  {
    path: '/configurator/dosingandfeeding/scales/edit/:id',
    name: <I18nMessage id="Edit" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: EditScale
  },
  {
    path: '/configurator/dosingandfeeding/scales/view/:id',
    name: <I18nMessage id="View" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: ViewScale
  },
  {
    path: '/configurator/dosingandfeeding/bins',
    exact: true,
    name: <I18nMessage id="Bins" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: Bins
  },
  {
    path: '/configurator/dosingandfeeding/bins/new',
    name: <I18nMessage id="New" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: AddBin
  },
  {
    path: '/configurator/dosingandfeeding/bins/edit/:id',
    name: <I18nMessage id="Edit" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: EditBin
  },
  {
    path: '/configurator/dosingandfeeding/bins/view/:id',
    name: <I18nMessage id="View" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: ViewBin
  },
  {
    path: '/configurator/dosingandfeeding/dosingpoints',
    exact: true,
    name: <I18nMessage id="Dosing Points" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: DosingPoints
  },
  {
    path: '/configurator/dosingandfeeding/dosingpoints/new',
    name: <I18nMessage id="New" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: AddDosingPoint
  },
  {
    path: '/configurator/dosingandfeeding/dosingpoints/edit/:id',
    name: <I18nMessage id="Edit" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: EditDosingPoint
  },
  {
    path: '/configurator/dosingandfeeding/dosingpoints/view/:id',
    name: <I18nMessage id="View" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: ViewDosingPoint
  },
  {
    path: '/configurator/dosingandfeeding/feedingpoints',
    exact: true,
    name: <I18nMessage id="Feeding Points" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: FeedingPoints
  },
  {
    path: '/configurator/dosingandfeeding/feedingpoints/new',
    name: <I18nMessage id="New" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: AddFeedingPoint
  },
  {
    path: '/configurator/dosingandfeeding/feedingpoints/edit/:id',
    name: <I18nMessage id="Edit" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: EditFeedingPoint
  },
  {
    path: '/configurator/dosingandfeeding/feedingpoints/view/:id',
    name: <I18nMessage id="View" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: ViewFeedingPoint
  },
  {
    path: '/configurator/mixermode',
    exact: true,
    name: <I18nMessage id="Mixing" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE']
  },
  {
    path: '/configurator/mixermode/actionparameter',
    exact: true,
    name: <I18nMessage id="Action Prameters" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: ActionParameter
  },
  {
    path: '/configurator/mixermode/actionparameter/new',
    name: <I18nMessage id="New" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: AddActionParameters
  },
  {
    path: '/configurator/mixermode/actionparameter/edit/:id',
    name: <I18nMessage id="Edit" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: EditActionParameters
  },
  {
    path: '/configurator/mixermode/actionparameter/view/:id',
    name: <I18nMessage id="Edit" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: ViewActionParameters
  },
  {
    path: '/configurator/mixermode/modeparameter',
    exact: true,
    name: <I18nMessage id="Mode Prameters" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: ModeParameter
  },
  {
    path: '/configurator/mixermode/modeparameter/new',
    name: <I18nMessage id="New" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: AddModeParameters
  },
  {
    path: '/configurator/mixermode/modeparameter/edit/:id',
    name: <I18nMessage id="Edit" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: EditModeParameters
  },
  {
    path: '/configurator/mixermode/modeparameter/view/:id',
    name: <I18nMessage id="View" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: ViewModeParameters
  },
  {
    path: '/configurator/mixermode/iram',
    exact: true,
    name: 'Iram',
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: Iram
  },
  {
    path: '/configurator/mixermode/iram/new',
    exact: true,
    name: 'AddNewIram',
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: AddNewIram
  },
  {
    path: '/configurator/mixermode/iram/view/:id',
    exact: true,
    name: 'ViewIram',
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: ViewIram
  },
  {
    path: '/configurator/mixermode/iram/edit/:id',
    exact: true,
    name: 'EditIram',
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: EditIram
  },
  {
    path: '/configurator/mixermode/mixingaction',
    exact: true,
    name: <I18nMessage id="Action" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: MixingAction
  },
  {
    path: '/configurator/mixermode/mixingaction/new',
    name: <I18nMessage id="New" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: NewMixingAction
  },
  {
    path: '/configurator/mixermode/mixingaction/edit/:id',
    name: <I18nMessage id="Edit" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: EditMixingAction
  },
  {
    path: '/configurator/mixermode/mixingaction/view/:id',
    name: <I18nMessage id="View" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: ViewMixingAction
  },
  {
    path: '/configurator/mixermode/mixingmode',
    exact: true,
    name: <I18nMessage id="Mode" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: MixingMode
  },
  {
    path: '/configurator/mixermode/mixingmode/new',
    name: <I18nMessage id="New" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: AddMixingMode
  },
  {
    path: '/configurator/mixermode/mixingmode/edit/:id',
    name: <I18nMessage id="Edit" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: EditMixingMode
  },
  {
    path: '/configurator/mixermode/mixingmode/view/:id',
    name: <I18nMessage id="View" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: ViewMixingMode
  },
  {
    path: '/configurator/additionalparameters',
    exact: true,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    name: <I18nMessage id="Additional Parameters" />
  },
  {
    path: '/configurator/additionalparameters/miscellaneousparameters',
    exact: true,
    name: <I18nMessage id="Miscellaneous Parameters" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: MiscellaneousParams
  },
  {
    path: '/configurator/additionalparameters/miscellaneousparameters/edit/:id',
    name: <I18nMessage id="Edit" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: EditMiscellaneousParams
  },
  {
    path: '/configurator/additionalparameters/miscellaneousparameters/view/:id',
    name: <I18nMessage id="View" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: ViewMiscellaneousParams
  },
  {
    path: '/configurator/additionalparameters/miscellaneousparameters/new',
    name: <I18nMessage id="New" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: AddMiscellaneousParameters
  },
  {
    path: '/configurator/additionalparameters/engineeringparameters',
    exact: true,
    name: <I18nMessage id="Engineering Parameters" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: EngineeringParams
  },
  {
    path: '/configurator/additionalparameters/engineeringparameters/new',
    name: <I18nMessage id="New" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: AddEngineeringParameters
  },
  {
    path: '/configurator/additionalparameters/engineeringparameters/edit/:id',
    name: <I18nMessage id="Edit" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: EditEngineeringParameters
  },
  {
    path: '/configurator/additionalparameters/engineeringparameters/view/:id',
    name: <I18nMessage id="View" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: ViewEngineeringParameters
  },
  {
    path: '/configurator/additionalparameters/reportparameters',
    exact: true,
    name: <I18nMessage id="Report Parameters" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: ReportParams
  },
  {
    path: '/configurator/additionalparameters/reportparameters/new',
    name: <I18nMessage id="New" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: AddReportParameters
  },
  {
    path: '/configurator/additionalparameters/reportparameters/edit/:id',
    name: <I18nMessage id="Edit" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: EditReportParameters
  },
  {
    path: '/configurator/additionalparameters/reportparameters/view/:id',
    name: <I18nMessage id="View" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: ViewReportParameters
  },
  {
    path: '/configurator/stoptime/stoptimereasons',
    exact: true,
    name: <I18nMessage id="Stop Reasons" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: StopTimeReasons
  },
  {
    path: '/configurator/stoptime/stoptimereasons/add',
    name: <I18nMessage id="Add" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    exact: true,
    component: AddStopTimeReasons
  },
  {
    path: '/configurator/stoptime/stoptimereasons/edit/:id',
    name: <I18nMessage id="Edit" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    exact: true,
    component: EditStopTimeReasons
  },
  {
    path: '/configurator/stoptime/stoptimereasons/view/:id',
    name: <I18nMessage id="View" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    exact: true,
    component: ViewStopTimeReasons
  },
  {
    path: '/recipemanagement/materialdirectory',
    name: <I18nMessage id="MaterialDirectory" />,
    exact: true,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: MaterialDirectory
  },
  {
    path: '/recipemanagement/materialdirectory/view/:id',
    name: <I18nMessage id="View" />,
    exact: true,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: ViewMaterialDirectory
  },
  {
    path: '/recipemanagement/materialdirectory/edit/:id',
    name: <I18nMessage id="View" />,
    exact: true,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: EditMaterialDirectory
  },
  {
    path: '/recipemanagement/materialdirectory/new',
    name: <I18nMessage id="New" />,
    exact: true,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: AddMaterialDirectory
  },
  {
    path: '/recipemanagement/binassignment',
    name: <I18nMessage id="Bin Assignment" />,
    exact: true,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: BinAssignment
  },
  {
    path: '/recipemanagement/recipes',
    name: <I18nMessage id="Recipes" />,
    exact: true,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: Recipes
  },
  {
    path: '/recipemanagement/recipes/addrecipe',
    name: <I18nMessage id="Add" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: AddRecipe
  },
  {
    path: '/recipemanagement/recipes/editrecipe/:id',
    name: <I18nMessage id="Edit" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: EditRecipe
  },
  {
    path: '/recipemanagement/recipes/view/:id',
    name: <I18nMessage id="View" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: ViewRecipe
  },
  {
    path: '/recipemanagement/recipes/:id/saveAs',
    name: <I18nMessage id="Save As" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: SaveAsRecipe
  },
  {
    path: '/scheduling/productionschedules/view/:id',
    name: <I18nMessage id="View" />,
    exact: true,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: ViewProductionSchedule
  },
  {
    path: '/scheduling/productionschedules/new',
    name: <I18nMessage id="Add" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: AddProductionSchedule
  },
  {
    path: '/scheduling/productionschedules/edit/:id',
    name: <I18nMessage id="Edit" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: EditProductionSchedule
  },
  {
    path: '/scheduling/productionschedules',
    name: <I18nMessage id="Production Schedules" />,
    exact: true,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: ProductionSchedules
  },
  {
    path:
      '/reporting/consumption/bymaterial/:lineid/:id/:fromdatetime/:todatetime',
    name: <I18nMessage id="Material Consumption" />,
    exact: true,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: ByMaterial
  },
  {
    path:
      '/reporting/consumption/bymaterials/:lineid/:materialcategoryid/:fromdatetime/:todatetime',
    name: <I18nMessage id="Material Consumptions" />,
    exact: true,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: ByMaterials
  },
  {
    path: '/reporting/consumption/bymaterials',
    name: <I18nMessage id="All Material Consumptions" />,
    exact: true,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: ByMaterials
  },
  {
    path: '/reporting/consumption/bymaterialcategory',
    name: <I18nMessage id="Material Category Consumption" />,
    exact: true,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: ByMaterialCategory
  },
  {
    path: '/reporting/consumption/bymaterial',
    name: <I18nMessage id="All Material Consumption" />,
    exact: true,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: ByMaterial
  },
  {
    path: '/reporting/consumption/bybatches',
    name: <I18nMessage id="Batches Consumption" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: ByBatches
  },
  {
    path: '/reporting/consumption/consumptionstatistics',
    name: <I18nMessage id="Consumption Statistics" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: ConsumptionStatistics
  },
  {
    path: '/reporting/production/byrecipe',
    name: <I18nMessage id="Dashboard" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: ProductionByRecipe
  },
  {
    path: '/reporting/production/bytime',
    name: <I18nMessage id="Production By time" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: ProductionByTime
  },
  {
    path: '/reporting/production/linedetails',
    name: <I18nMessage id="Line Details" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: LineDetails
  },
  {
    path: '/reporting/batch/batchsummary',
    name: <I18nMessage id="Batch Summary" />,
    exact: true,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: BatchSummary
  },
  {
    path: '/reporting/batch/batchdetails/:id',
    name: <I18nMessage id="Batch Details" />,
    privileges: [],
    exact: true,
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: BatchDetails
  },
  {
    path: [
      '/reporting/batch/batchsummary/:id/alarms',
      '/reporting/pcs/report/alarms'
    ],
    name: <I18nMessage id="Alarm Details" />,
    exact: true,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: AlarmDetails
  },
  {
    path: '/reporting/diagnostics/realtime',
    name: <I18nMessage id="Realtime" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: Realtime
  },
  {
    path: '/reporting/diagnostics/outofspec',
    name: <I18nMessage id="OutOfSpec" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: OutOfSpec
  },
  {
    path: '/reporting/diagnostics/compare',
    name: <I18nMessage id="CompareReportparameters" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: CompareReportparameters
  },
  {
    path: '/reporting/diagnostics/view',
    name: <I18nMessage id="Diagnostics" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: DiagnosticsParameters
  },
  {
    path: '/reporting/diagnostics/downstream',
    name: <I18nMessage id="Downstream" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: DownstreamParameters
  },
  {
    path: '/reporting/pcs/report',
    name: <I18nMessage id="Report" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: pcsReport
  },
  {
    path: '/reporting/pcs/pressure',
    name: <I18nMessage id="Pressure" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: pcsPressure
  },
  {
    path: '/reporting/others/binHistory',
    name: <I18nMessage id="Bin History" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: BinHistory
  },
  {
    path: '/reporting/others/recipeReport',
    name: <I18nMessage id="Recipe Report" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: RecipeReport
  },
  {
    path: '/reporting/others/materialProductionReport',
    name: <I18nMessage id="Material Production Report" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: MaterialProductionReport
  },
  {
    path: '/reporting/others/materialConsumptionReport',
    name: <I18nMessage id="Material Consumption Report" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: MaterialConsumptionReport
  },
  {
    path: '/reporting/others/shiftReport',
    name: <I18nMessage id="Shift Report" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: ShiftReport
  },
  {
    path: '/reporting/others/shiftWiseReport',
    name: <I18nMessage id="Shift Wise Report" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: ShiftWiseReport
  },
  {
    path: '/reporting/others/mixerKWReport',
    name: <I18nMessage id="MixerKW Report" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: MixerKWReport
  },
  {
    path: '/reporting/others/batchReport',
    name: <I18nMessage id="Batch Report" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: BatchReport
  },
  {
    path: '/reporting/others/lotReport',
    name: <I18nMessage id="Lot Report" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: LotReport
  },
  {
    path:
      '/reporting/diagnostics/specificview/:lineId/:recipeId/:batchId/:productionScheduleId/:fromDate/:toDate',
    name: <I18nMessage id="Diagnostics" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: DiagnosticsParameters
  },
  {
    path: '/users',
    exact: true,
    name: <I18nMessage id="Users" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN'],
    component: Users
  },
  {
    path: '/users/createuser',
    name: <I18nMessage id="Create User" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN'],
    component: CreateUser
  },
  {
    path: '/users/edituser/:userId',
    name: <I18nMessage id="Edit" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN'],
    component: EditUser
  },
  {
    path: '/copyparameter/:type',
    name: <I18nMessage id="copy parameters" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN'],
    component: CopyParameterRouter
  },
  {
    path: '/consumption/consumptionstatistics/spcgraph',
    name: <I18nMessage id="Consumption Statistic Graph" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN'],
    component: consumptionStatisticsOrderWiseGraphRoute
  },
  {
    path: '/import/:type',
    name: <I18nMessage id="import" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN'],
    component: importRoute
  },
  {
    path: '/reporting/others/stoptimereport',
    name: <I18nMessage id="Stop Time Report" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: StopTimeReport
  },
  {
    path: '/configurator/stoptime/stoptimegroup',
    exact: true,
    name: <I18nMessage id="Stop Time Group" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN'],
    component: StopTimeGroupRoutes
  },
  {
    path: '/configurator/stoptime/stoptimegroup/new',
    name: <I18nMessage id="New" />,
    privileges: ['_LINE_CREATE'],
    role: ['_SUPER'],
    component: AddStopTimeGroupRoutes
  },
  {
    path: '/configurator/stoptime/stoptimegroup/view/:id',
    name: <I18nMessage id="View" />,
    privileges: ['_LINE_UPDATE'],
    role: ['_SUPER', 'ADMIN', 'PREVILAGE'],
    component: ViewStopTimeGroupRoutes
  },
  {
    path: '/configurator/stoptime/stoptimegroup/edit/:id',
    name: <I18nMessage id="Edit" />,
    privileges: ['_LINE_UPDATE'],
    role: ['_SUPER', 'ADMIN', 'PRIVILEGE'],
    component: EditStopTimeGroupRoutes
  }
];

export default routes;
