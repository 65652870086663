import decode from 'jwt-decode';
import React from 'react';
import I18nMessage from '../i18n/I18nMessage';
import BinAssignment from '../../views/RecipeManagement/BinAssignment';

// Get token from local storage.
function getUserInfo() {
  // Retrieves the user token from localStorage
  let token = localStorage.getItem('id_token');
  if (token) {
    return decode(token);
  }
}

export function getNavigation() {
  let userInfo = getUserInfo();
  let navigation = [];

  if (userInfo) {
    if (userInfo.role === '_SUPER') {
      navigation = [
        {
          name: <I18nMessage id="Dashboard" />,
          url: '/dashboard',
          icon: 'icon-home'
        },
        {
          name: <I18nMessage id="Configurator" />,
          // url: '/configurator',
          icon: 'icon-settings',
          children: [
            {
              name: <I18nMessage id="Line" />,
              url: '/configurator/line',
              icon: 'fa fa-industry'
            },
            {
              name: <I18nMessage id="Shift" />,
              url: '/configurator/shift',
              icon: 'fa fa-th'
            },
            {
              name: <I18nMessage id="Material Categories" />,
              url: '/configurator/materialcategories',
              icon: 'fa fa-sitemap'
            },
            {
              name: <I18nMessage id="Dosing and Feeding" />,
              url: '/configurator/dosingandfeeding',
              icon: 'fa fa-compress',
              children: [
                {
                  name: <I18nMessage id="Scales" />,
                  url: '/configurator/dosingandfeeding/scales',
                  icon: 'fa fa-balance-scale'
                },
                {
                  name: <I18nMessage id="Bin" />,
                  url: '/configurator/dosingandfeeding/bins',
                  icon: 'fa fa-bitbucket'
                },
                {
                  name: <I18nMessage id="Dosing Point" />,
                  url: '/configurator/dosingandfeeding/dosingpoints',
                  icon: 'fa fa-compress'
                },
                {
                  name: <I18nMessage id="Feeding Point" />,
                  url: '/configurator/dosingandfeeding/feedingpoints',
                  icon: 'fa fa-compress'
                }
              ]
            },
            {
              name: <I18nMessage id="Mixing" />,
              // url: '/configurator/mixermode',
              icon: 'fa fa-modx',
              children: [
                {
                  name: <I18nMessage id="iRam" />,
                  url: '/configurator/mixermode/iram',
                  icon: 'fa fa-arrows-v'
                },
                {
                  name: <I18nMessage id="Action Parameter" />,
                  url: '/configurator/mixermode/actionparameter',
                  icon: 'fa fa-superpowers'
                },
                {
                  name: <I18nMessage id="Mode Parameter" />,
                  url: '/configurator/mixermode/modeparameter',
                  icon: 'fa fa-superpowers'
                },
                {
                  name: <I18nMessage id="Action" />,
                  url: '/configurator/mixermode/mixingaction',
                  icon: 'fa fa-arrows'
                },
                {
                  name: <I18nMessage id="Mode" />,
                  url: '/configurator/mixermode/mixingmode',
                  icon: 'fa fa-bolt'
                }
              ]
            },
            {
              name: <I18nMessage id="Parameters" />,
              // url: '/configurator/additionalparameters',
              icon: 'fa fa-braille',
              children: [
                {
                  name: <I18nMessage id="Miscellaneous" />,
                  url:
                    '/configurator/additionalparameters/miscellaneousparameters',
                  icon: 'fa fa-superpowers'
                },
                {
                  name: <I18nMessage id="Engineering" />,
                  url:
                    '/configurator/additionalparameters/engineeringparameters',
                  icon: 'fa fa-superpowers'
                },
                {
                  name: <I18nMessage id="Report" />,
                  url: '/configurator/additionalparameters/reportparameters',
                  icon: 'fa fa-superpowers'
                }
              ]
            }
            // {
            //   name: <I18nMessage id="Stop Time" />,
            //   // url: '/configurator/additionalparameters',
            //   icon: 'fa fa-braille',
            //   children: [
            //     {
            //       name: <I18nMessage id="Stop Time Group" />,
            //       url: '/configurator/stoptime/stoptimegroup',
            //       icon: 'fa fa-superpowers'
            //     },
            //     {
            //       name: <I18nMessage id="Stop TIme Reason" />,
            //       url: '/configurator/stoptime/stoptimereasons',
            //       icon: 'fa fa-question'
            //     }
            //   ]
            // }
          ]
        },
        {
          name: <I18nMessage id="Recipe Management" />,
          // url: '/recipemanagement',
          icon: 'fa fa-snowflake-o',
          children: [
            {
              name: <I18nMessage id="Material Directory" />,
              url: '/recipemanagement/materialdirectory',
              icon: 'fa fa-linode'
            },
            {
              name: <I18nMessage id="Bin Assignment" />,
              url: '/recipemanagement/binassignment',
              icon: 'fa fa-pencil-square-o'
            },
            {
              name: <I18nMessage id="Recipes" />,
              url: '/recipemanagement/recipes',
              icon: 'fa fa-file-text-o'
            }
          ]
        },
        {
          name: <I18nMessage id="Scheduling" />,
          icon: 'icon-layers',
          children: [
            {
              name: <I18nMessage id="Production Schedules" />,
              url: '/scheduling/productionschedules',
              icon: 'fa fa-calendar'
            }
          ]
        },
        {
          name: <I18nMessage id="Reporting" />,
          icon: 'icon-docs',
          children: [
            {
              name: <I18nMessage id="Consumption" />,
              icon: 'fa fa-bar-chart',
              children: [
                {
                  name: <I18nMessage id="Material Category" />,
                  url: '/reporting/consumption/bymaterialcategory',
                  icon: 'fa fa-sitemap'
                },
                {
                  name: <I18nMessage id="Materials" />,
                  url: '/reporting/consumption/bymaterials',
                  icon: 'fa fa-linode'
                },
                {
                  name: <I18nMessage id="Batches" />,
                  url: '/reporting/consumption/bybatches',
                  icon: 'fa fa-list-ul'
                },
                {
                  name: <I18nMessage id="Statistics" />,
                  url: '/reporting/consumption/consumptionstatistics',
                  icon: 'fa fa-pie-chart'
                }
              ]
            },
            {
              name: <I18nMessage id="Production" />,
              icon: 'fa fa-line-chart',
              children: [
                {
                  name: <I18nMessage id="Production dashboard" />,
                  url: '/reporting/production/byrecipe',
                  icon: 'fa fa-align-justify'
                },
                {
                  name: <I18nMessage id="Time based" />,
                  url: '/reporting/production/bytime',
                  icon: 'fa fa-clock-o'
                }
              ]
            },
            {
              name: <I18nMessage id="Batch" />,
              icon: 'fa fa-list-ul',
              children: [
                {
                  name: <I18nMessage id="Batch Summary" />,
                  url: '/reporting/batch/batchsummary',
                  icon: 'fa fa-list-ul'
                }
              ]
            },
            {
              name: <I18nMessage id="Diagnostics" />,
              icon: 'fa fa-calendar-plus-o',
              children: [
                {
                  name: <I18nMessage id="Parameters" />,
                  url: '/reporting/diagnostics/view',
                  icon: 'fa fa-braille'
                },
                {
                  name: <I18nMessage id="Out of Spec" />,
                  url: '/reporting/diagnostics/outofspec',
                  icon: 'fa fa-braille'
                },
                {
                  name: <I18nMessage id="Compare" />,
                  url: '/reporting/diagnostics/compare',
                  icon: 'fa fa-braille'
                },
                {
                  name: <I18nMessage id="Downstream" />,
                  url: '/reporting/diagnostics/downstream',
                  icon: 'fa fa-braille'
                }
              ]
            },
            {
              name: <I18nMessage id="PCS" />,
              icon: 'fa fa-truck',
              children: [
                {
                  name: <I18nMessage id="Report" />,
                  url: '/reporting/pcs/report',
                  icon: 'icon-docs'
                },
                {
                  name: <I18nMessage id="Pressure" />,
                  url: '/reporting/pcs/pressure',
                  icon: 'fa fa-tachometer'
                }
              ]
            },
            {
              name: <I18nMessage id="Custom Reports" />,
              icon: 'fa fa-indent',
              children: [
                {
                  name: <I18nMessage id="Bin History" />,
                  url: '/reporting/Others/BinHistory/binHistory',
                  icon: 'fa fa-file-text'
                },
                // {
                //   name: <I18nMessage id="Stop Time Report" />,
                //   url: '/reporting/Others/stoptimereport',
                //   icon: 'fa fa-file-text'
                // },
                {
                  name: <I18nMessage id="Shift Report" />,
                  url: '/reporting/Others/ShiftReport/shiftReport',
                  icon: 'fa fa-file-text'
                },
                {
                  name: <I18nMessage id="Shift Wise Report" />,
                  url: '/reporting/Others/shiftWiseReport',
                  icon: 'fa fa-file-text'
                },
                {
                  name: <I18nMessage id="Recipe Report" />,
                  url: '/reporting/Others/RecipeReport/recipeReport',
                  icon: 'fa fa-file-text'
                },
                {
                  name: <I18nMessage id="MixerKW Report" />,
                  url: '/reporting/Others/MixerKWReport/mixerKWReport',
                  icon: 'fa fa-file-text'
                },
                {
                  name: <I18nMessage id="Material Production Report" />,
                  url: '/reporting/others/materialProductionReport',
                  icon: 'fa fa-file-text'
                },
                {
                  name: <I18nMessage id="Material Consumption Report" />,
                  url: '/reporting/others/materialConsumptionReport',
                  icon: 'fa fa-file-text'
                },
                {
                  name: <I18nMessage id="Batch Report" />,
                  url: '/reporting/Others/BatchReport/batchReport',
                  icon: 'fa fa-file-text'
                },
                {
                  name: <I18nMessage id="Lot Report" />,
                  url: '/reporting/Others/LotReport/lotReport',
                  icon: 'fa fa-file-text'
                }
              ]
            }
          ]
        },
        {
          name: <I18nMessage id="User Management" />,
          icon: 'icon-people',
          children: [
            {
              name: <I18nMessage id="Users" />,
              url: '/users',
              icon: 'icon-user-follow'
            }
          ]
        }
      ];
    } else if (userInfo.role === 'ADMIN') {
      navigation = [
        {
          name: <I18nMessage id="Dashboard" />,
          url: '/dashboard',
          icon: 'icon-home'
        },
        {
          name: <I18nMessage id="Configurator" />,
          url: '/configurator',
          icon: 'icon-settings',
          children: [
            {
              name: <I18nMessage id="Line" />,
              url: '/configurator/line',
              icon: 'fa fa-industry'
            },
            {
              name: <I18nMessage id="Shift" />,
              url: '/configurator/shift',
              icon: 'icon-drop'
            },
            {
              name: <I18nMessage id="Material Categories" />,
              url: '/configurator/materialcategories',
              icon: 'fa fa-sitemap'
            },
            {
              name: <I18nMessage id="Dosing and Feeding" />,
              url: '/configurator/dosingandfeeding',
              icon: 'fa fa-compress',
              children: [
                {
                  name: <I18nMessage id="Scales" />,
                  url: '/configurator/dosingandfeeding/scales',
                  icon: 'fa fa-balance-scale'
                },
                {
                  name: <I18nMessage id="Bin" />,
                  url: '/configurator/dosingandfeeding/bins',
                  icon: 'fa fa-bitbucket'
                },
                {
                  name: <I18nMessage id="Dosing Point" />,
                  url: '/configurator/dosingandfeeding/dosingpoints',
                  icon: 'fa fa-compress'
                },
                {
                  name: <I18nMessage id="Feeding Point" />,
                  url: '/configurator/dosingandfeeding/feedingpoints',
                  icon: 'fa fa-compress'
                }
              ]
            },
            {
              name: <I18nMessage id="Mixing" />,
              url: '/configurator/mixermode',
              icon: 'fa fa-modx',
              children: [
                {
                  name: <I18nMessage id="Action Parameter" />,
                  url: '/configurator/mixermode/actionparameter',
                  icon: 'fa fa-superpowers'
                },
                {
                  name: <I18nMessage id="Mode Parameter" />,
                  url: '/configurator/mixermode/modeparameter',
                  icon: 'fa fa-superpowers'
                },
                {
                  name: <I18nMessage id="Action" />,
                  url: '/configurator/mixermode/mixingaction',
                  icon: 'fa fa-arrows'
                },
                {
                  name: <I18nMessage id="Mode" />,
                  url: '/configurator/mixermode/mixingmode',
                  icon: 'fa fa-bolt'
                },
                {
                  name: <I18nMessage id="iRam" />,
                  url: '/configurator/mixermode/iram',
                  icon: 'fa fa-arrows-v'
                }
              ]
            },
            {
              name: <I18nMessage id="Parameters" />,
              url: '/configurator/additionalparameters',
              icon: 'fa fa-braille',
              children: [
                {
                  name: <I18nMessage id="Miscellaneous" />,
                  url:
                    '/configurator/additionalparameters/miscellaneousparameters',
                  icon: 'fa fa-superpowers'
                },
                {
                  name: <I18nMessage id="Engineering" />,
                  url:
                    '/configurator/additionalparameters/engineeringparameters',
                  icon: 'fa fa-superpowers'
                },
                {
                  name: <I18nMessage id="Report" />,
                  url: '/configurator/additionalparameters/reportparameters',
                  icon: 'fa fa-superpowers'
                }
              ]
            }
            // {
            //   name: <I18nMessage id="Stop Time" />,
            //   // url: '/configurator/additionalparameters',
            //   icon: 'fa fa-braille',
            //   children: [
            //     {
            //       name: <I18nMessage id="Stop Time Group" />,
            //       url: '/configurator/stoptime/stoptimegroup',
            //       icon: 'fa fa-superpowers'
            //     },
            //     {
            //       name: <I18nMessage id="Stop TIme Reason" />,
            //       url: '/configurator/stoptime/stoptimereasons',
            //       icon: 'fa fa-question'
            //     }
            //   ]
            // }
          ]
        },
        {
          name: <I18nMessage id="Recipe Management" />,
          url: '/recipemanagement',
          icon: 'fa fa-snowflake-o',
          children: [
            {
              name: <I18nMessage id="Material Directory" />,
              url: '/recipemanagement/materialdirectory',
              icon: 'fa fa-linode'
            },
            {
              name: <I18nMessage id="Bin Assignment" />,
              url: '/recipemanagement/binassignment',
              icon: 'fa fa-pencil-square-o'
            },
            {
              name: <I18nMessage id="Recipes" />,
              url: '/recipemanagement/recipes',
              icon: 'fa fa-file-text-o'
            }
          ]
        },
        {
          name: <I18nMessage id="Scheduling" />,
          icon: 'icon-layers',
          children: [
            {
              name: <I18nMessage id="Production Schedules" />,
              url: '/scheduling/productionschedules',
              icon: 'fa fa-calendar'
            }
          ]
        },
        {
          name: <I18nMessage id="Reporting" />,
          icon: 'icon-docs',
          children: [
            {
              name: <I18nMessage id="Consumption" />,
              icon: 'fa fa-bar-chart',
              children: [
                {
                  name: <I18nMessage id="Material Category" />,
                  url: '/reporting/consumption/bymaterialcategory',
                  icon: 'fa fa-sitemap'
                },
                {
                  name: <I18nMessage id="Materials" />,
                  url: '/reporting/consumption/bymaterials',
                  icon: 'fa fa-linode'
                },
                {
                  name: <I18nMessage id="Batches" />,
                  url: '/reporting/consumption/bybatches',
                  icon: 'fa fa-list-ul'
                },
                {
                  name: <I18nMessage id="Statistics" />,
                  url: '/reporting/consumption/consumptionstatistics',
                  icon: 'fa fa-pie-chart'
                }
              ]
            },
            {
              name: <I18nMessage id="Production" />,
              icon: 'fa fa-line-chart',
              children: [
                {
                  name: <I18nMessage id="Production dashboard" />,
                  url: '/reporting/production/byrecipe',
                  icon: 'fa fa-align-justify'
                },
                {
                  name: <I18nMessage id="Time based" />,
                  url: '/reporting/production/bytime',
                  icon: 'fa fa-clock-o'
                }
              ]
            },
            {
              name: <I18nMessage id="Batch" />,
              icon: 'fa fa-list-ul',
              children: [
                {
                  name: <I18nMessage id="Batch Summary" />,
                  url: '/reporting/batch/batchsummary',
                  icon: 'fa fa-list-ul'
                }
              ]
            },
            {
              name: <I18nMessage id="Diagnostics" />,
              icon: 'fa fa-calendar-plus-o',
              children: [
                {
                  name: <I18nMessage id="Parameters" />,
                  url: '/reporting/diagnostics/view',
                  icon: 'fa fa-braille'
                },
                {
                  name: <I18nMessage id="Out of Spec" />,
                  url: '/reporting/diagnostics/outofspec',
                  icon: 'fa fa-braille'
                },
                {
                  name: <I18nMessage id="Compare" />,
                  url: '/reporting/diagnostics/compare',
                  icon: 'fa fa-braille'
                }
              ]
            },
            {
              name: <I18nMessage id="PCS" />,
              icon: 'fa fa-truck',
              children: [
                {
                  name: <I18nMessage id="Report" />,
                  url: '/reporting/pcs/report',
                  icon: 'icon-docs'
                },
                {
                  name: <I18nMessage id="Pressure" />,
                  url: '/reporting/pcs/pressure',
                  icon: 'fa fa-tachometer'
                }
              ]
            },
            {
              name: <I18nMessage id="Custom Reports" />,
              icon: 'fa fa-indent',
              children: [
                {
                  name: <I18nMessage id="Bin History" />,
                  url: '/reporting/Others/BinHistory/binHistory',
                  icon: 'fa fa-file-text'
                },
                // {
                //   name: <I18nMessage id="Stop Time Report" />,
                //   url: '/reporting/Others/stoptimereport',
                //   icon: 'fa fa-file-text'
                // },
                {
                  name: <I18nMessage id="Shift Report" />,
                  url: '/reporting/Others/ShiftReport/shiftReport',
                  icon: 'fa fa-file-text'
                },
                {
                  name: <I18nMessage id="Shift Wise Report" />,
                  url: '/reporting/Others/shiftWiseReport',
                  icon: 'fa fa-file-text'
                },
                {
                  name: <I18nMessage id="Recipe Report" />,
                  url: '/reporting/Others/RecipeReport/recipeReport',
                  icon: 'fa fa-file-text'
                },
                {
                  name: <I18nMessage id="MixerKW Report" />,
                  url: '/reporting/Others/MixerKWReport/mixerKWReport',
                  icon: 'fa fa-file-text'
                },
                {
                  name: <I18nMessage id="Material Production Report" />,
                  url: '/reporting/others/materialProductionReport',
                  icon: 'fa fa-file-text'
                },
                {
                  name: <I18nMessage id="Material Consumption Report" />,
                  url: '/reporting/others/materialConsumptionReport',
                  icon: 'fa fa-file-text'
                },
                {
                  name: <I18nMessage id="Batch Report" />,
                  url: '/reporting/Others/BatchReport/batchReport',
                  icon: 'fa fa-file-text'
                },
                {
                  name: <I18nMessage id="Lot Report" />,
                  url: '/reporting/Others/LotReport/lotReport',
                  icon: 'fa fa-file-text'
                }
              ]
            }
          ]
        },
        {
          name: <I18nMessage id="User Management" />,
          icon: 'icon-people',
          children: [
            {
              name: <I18nMessage id="Users" />,
              url: '/users',
              icon: 'icon-user-follow'
            }
          ]
        }
      ];
    } else if (userInfo.role === 'PRIVILEGE') {
      navigation = [
        {
          name: <I18nMessage id="Dashboard" />,
          url: '/dashboard',
          icon: 'icon-home'
        }
      ];

      // Configurator check.
      let configuratorArray = [
        '_LINE_CREATE',
        '_LINE_READ',
        '_LINE_UPDATE',
        '_LINE_DELETE',
        'CONFIGURATOR_CREATE',
        'CONFIGURATOR_READ',
        'CONFIGURATOR_UPDATE',
        'CONFIGURATOR_DELETE'
      ];
      const ConfiguratorCheck = userInfo.privileges.some(
        r => configuratorArray.indexOf(r) >= 0
      );
      let recipeChildren = [];
      if (ConfiguratorCheck) {
        navigation.push({
          name: <I18nMessage id="Configurator" />,
          url: '/configurator',
          icon: 'icon-settings',
          children: [
            {
              name: <I18nMessage id="Line" />,
              url: '/configurator/line',
              icon: 'fa fa-industry'
            },
            {
              name: <I18nMessage id="Shift" />,
              url: '/configurator/shift',
              icon: 'icon-drop'
            },
            {
              name: <I18nMessage id="Material Categories" />,
              url: '/configurator/materialcategories',
              icon: 'fa fa-sitemap'
            },
            {
              name: <I18nMessage id="Dosing and Feeding" />,
              url: '/configurator/dosingandfeeding',
              icon: 'fa fa-compress',
              children: [
                {
                  name: <I18nMessage id="Scales" />,
                  url: '/configurator/dosingandfeeding/scales',
                  icon: 'fa fa-balance-scale'
                },
                {
                  name: <I18nMessage id="Bin" />,
                  url: '/configurator/dosingandfeeding/bins',
                  icon: 'fa fa-bitbucket'
                },
                {
                  name: <I18nMessage id="Dosing Point" />,
                  url: '/configurator/dosingandfeeding/dosingpoints',
                  icon: 'fa fa-compress'
                },
                {
                  name: <I18nMessage id="Feeding Point" />,
                  url: '/configurator/dosingandfeeding/feedingpoints',
                  icon: 'fa fa-compress'
                }
              ]
            },
            {
              name: <I18nMessage id="Mixing" />,
              url: '/configurator/mixermode',
              icon: 'fa fa-modx',
              children: [
                {
                  name: <I18nMessage id="Action Parameter" />,
                  url: '/configurator/mixermode/actionparameter',
                  icon: 'fa fa-superpowers'
                },
                {
                  name: <I18nMessage id="Mode Parameter" />,
                  url: '/configurator/mixermode/modeparameter',
                  icon: 'fa fa-superpowers'
                },
                {
                  name: <I18nMessage id="Action" />,
                  url: '/configurator/mixermode/mixingaction',
                  icon: 'fa fa-arrows'
                },
                {
                  name: <I18nMessage id="Mode" />,
                  url: '/configurator/mixermode/mixingmode',
                  icon: 'fa fa-bolt'
                },
                {
                  name: <I18nMessage id="iRam" />,
                  url: '/configurator/mixermode/iram',
                  icon: 'fa fa-arrows-v'
                }
              ]
            },
            {
              name: <I18nMessage id="Parameters" />,
              url: '/configurator/additionalparameters',
              icon: 'fa fa-braille',
              children: [
                {
                  name: <I18nMessage id="Miscellaneous" />,
                  url:
                    '/configurator/additionalparameters/miscellaneousparameters',
                  icon: 'fa fa-superpowers'
                },
                {
                  name: <I18nMessage id="Engineering" />,
                  url:
                    '/configurator/additionalparameters/engineeringparameters',
                  icon: 'fa fa-superpowers'
                },
                {
                  name: <I18nMessage id="Report" />,
                  url: '/configurator/additionalparameters/reportparameters',
                  icon: 'fa fa-superpowers'
                }
              ]
            }
            // {
            //   name: <I18nMessage id="Stop Time" />,
            //   // url: '/configurator/additionalparameters',
            //   icon: 'fa fa-braille',
            //   children: [
            //     {
            //       name: <I18nMessage id="Stop Time Group" />,
            //       url: '/configurator/stoptime/stoptimegroup',
            //       icon: 'fa fa-superpowers'
            //     },
            //     {
            //       name: <I18nMessage id="Stop TIme Reason" />,
            //       url: '/configurator/stoptime/stoptimereasons',
            //       icon: 'fa fa-question'
            //     }
            //   ]
            // }
          ]
        });
        recipeChildren.push({
          name: <I18nMessage id="Bin Assignment" />,
          url: '/recipemanagement/binassignment',
          icon: 'fa fa-pencil-square-o'
        });
      }

      // Recipe management check.
      let recipeManagementArray = [
        'RECIPE_CREATE',
        'RECIPE_READ',
        'RECIPE_UPDATE',
        'RECIPE_DELETE'
      ];
      const recipeManagementCheck = userInfo.privileges.some(
        r => recipeManagementArray.indexOf(r) >= 0
      );
      if (recipeManagementCheck) {
        recipeChildren.push({
          name: <I18nMessage id="Material Directory" />,
          url: '/recipemanagement/materialdirectory',
          icon: 'fa fa-linode'
        });
        recipeChildren.push({
          name: <I18nMessage id="Recipes" />,
          url: '/recipemanagement/recipes',
          icon: 'fa fa-file-text-o'
        });
      }
      if (recipeChildren.length > 0) {
        navigation.push({
          name: <I18nMessage id="Recipe Management" />,
          url: '/recipemanagement',
          icon: 'fa fa-snowflake-o',
          children: recipeChildren
        });
      }

      // Configurator check.
      let scheduleCheckArray = [
        'SCHEDULE_CREATE',
        'SCHEDULE_READ',
        'SCHEDULE_UPDATE',
        'SCHEDULE_DELETE'
      ];
      const scheduleCheck = userInfo.privileges.some(
        r => scheduleCheckArray.indexOf(r) >= 0
      );
      if (scheduleCheck) {
        navigation.push({
          name: <I18nMessage id="Scheduling" />,
          icon: 'icon-layers',
          children: [
            {
              name: <I18nMessage id="Production Schedules" />,
              url: '/scheduling/productionschedules',
              icon: 'fa fa-calendar'
            }
          ]
        });
      }

      // Configurator check.
      let reportsArray = [
        'REPORTS_CREATE',
        'REPORTS_READ',
        'REPORTS_UPDATE',
        'REPORTS_DELETE'
      ];
      const reportsCheck = userInfo.privileges.some(
        r => reportsArray.indexOf(r) >= 0
      );
      if (reportsCheck) {
        navigation.push({
          name: <I18nMessage id="Reporting" />,
          icon: 'icon-docs',
          children: [
            {
              name: <I18nMessage id="Consumption" />,
              icon: 'fa fa-bar-chart',
              children: [
                {
                  name: <I18nMessage id="Material Category" />,
                  url: '/reporting/consumption/bymaterialcategory',
                  icon: 'fa fa-sitemap'
                },
                {
                  name: <I18nMessage id="Materials" />,
                  url: '/reporting/consumption/bymaterials',
                  icon: 'fa fa-linode'
                },
                {
                  name: <I18nMessage id="Batches " />,
                  url: '/reporting/consumption/bybatches',
                  icon: 'fa fa-list-ul'
                },
                {
                  name: <I18nMessage id="Statistics" />,
                  url: '/reporting/consumption/consumptionstatistics',
                  icon: 'fa fa-pie-chart'
                }
              ]
            },
            {
              name: <I18nMessage id="Production" />,
              icon: 'fa fa-line-chart',
              children: [
                {
                  name: <I18nMessage id="Production dashboard" />,
                  url: '/reporting/production/byrecipe',
                  icon: 'fa fa-align-justify'
                },
                {
                  name: <I18nMessage id="Time based" />,
                  url: '/reporting/production/bytime',
                  icon: 'fa fa-clock-o'
                }
              ]
            },
            {
              name: <I18nMessage id="Batch" />,
              icon: 'fa fa-list-ul',
              children: [
                {
                  name: <I18nMessage id="Batch Summary" />,
                  url: '/reporting/batch/batchsummary',
                  icon: 'fa fa-list-ul'
                }
              ]
            },
            {
              name: <I18nMessage id="Diagnostics" />,
              icon: 'fa fa-calendar-plus-o',
              children: [
                {
                  name: <I18nMessage id="Parameters" />,
                  url: '/reporting/diagnostics/view',
                  icon: 'fa fa-braille'
                },
                {
                  name: <I18nMessage id="Out of Spec" />,
                  url: '/reporting/diagnostics/outofspec',
                  icon: 'fa fa-braille'
                },
                {
                  name: <I18nMessage id="Compare" />,
                  url: '/reporting/diagnostics/compare',
                  icon: 'fa fa-braille'
                }
              ]
            },
            {
              name: <I18nMessage id="PCS" />,
              icon: 'fa fa-truck',
              children: [
                {
                  name: <I18nMessage id="Report" />,
                  url: '/reporting/pcs/report',
                  icon: 'icon-docs'
                },
                {
                  name: <I18nMessage id="Pressure" />,
                  url: '/reporting/pcs/pressure',
                  icon: 'fa fa-tachometer'
                }
              ]
            },
            {
              name: <I18nMessage id="Custom Reports" />,
              icon: 'fa fa-indent',
              children: [
                {
                  name: <I18nMessage id="Bin History" />,
                  url: '/reporting/Others/BinHistory/binHistory',
                  icon: 'fa fa-file-text'
                },
                // {
                //   name: <I18nMessage id="Stop Time Report" />,
                //   url: '/reporting/Others/stoptimereport',
                //   icon: 'fa fa-file-text'
                // },
                {
                  name: <I18nMessage id="Shift Report" />,
                  url: '/reporting/Others/ShiftReport/shiftReport',
                  icon: 'fa fa-file-text'
                },
                {
                  name: <I18nMessage id="Shift Wise Report" />,
                  url: '/reporting/Others/shiftWiseReport',
                  icon: 'fa fa-file-text'
                },
                {
                  name: <I18nMessage id="Recipe Report" />,
                  url: '/reporting/Others/RecipeReport/recipeReport',
                  icon: 'fa fa-file-text'
                },
                {
                  name: <I18nMessage id="MixerKW Report" />,
                  url: '/reporting/Others/MixerKWReport/mixerKWReport',
                  icon: 'fa fa-file-text'
                },
                {
                  name: <I18nMessage id="Material Production Report" />,
                  url: '/reporting/others/materialProductionReport',
                  icon: 'fa fa-file-text'
                },
                {
                  name: <I18nMessage id="Material Consumption Report" />,
                  url: '/reporting/others/materialConsumptionReport',
                  icon: 'fa fa-file-text'
                },
                {
                  name: <I18nMessage id="Batch Report" />,
                  url: '/reporting/Others/BatchReport/batchReport',
                  icon: 'fa fa-file-text'
                },
                {
                  name: <I18nMessage id="Lot Report" />,
                  url: '/reporting/Others/LotReport/lotReport',
                  icon: 'fa fa-file-text'
                }
              ]
            }
          ]
        });
      }
    }
  }

  return navigation;
}
