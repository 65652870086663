import React, { Component } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Input,
  InputGroup,
  FormFeedback,
  InputGroupAddon,
  InputGroupText,
  Row,
  Form,
  FormGroup
} from 'reactstrap';
import AuthService from '../AuthService/authentication';
import { withAlert } from 'react-alert';
import FormValidator from '../UtilitiesService/FormValidator';
import I18nMessage from '../i18n/I18nMessage';
import localStorage from 'localStorage';
import Loader from '../UtilitiesService/Loader';

class Login extends Component {
  constructor() {
    super();

    this.validator = new FormValidator([
      {
        field: 'email',
        method: 'isEmpty',
        validWhen: false,
        message: <I18nMessage id="Please provide an email address" />
      },
      {
        field: 'email',
        method: 'isEmail',
        validWhen: true,
        message: <I18nMessage id="Invalid Email address" />
      },
      {
        field: 'password',
        method: 'isEmpty',
        validWhen: false,
        message: <I18nMessage id="Please Enter your password" />
      }
    ]);

    this.state = {
      email: '',
      password: '',
      loading: false,
      validation: this.validator.initialize()
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.Auth = new AuthService();
    this.formTouched = false;
  }

  componentDidMount() {
    this.setState({ validation: this.validator.initialize() });
    this.setState({ systemType: localStorage.getItem('systemType') });
  }

  handleFormSubmit(e) {
    e.preventDefault();
    // Compute Validation.
    const validation = this.validator.validate(this.state);
    // Remove localstorage value if present
    if (validation.isValid) {
      // Remove Token.
      localStorage.removeItem('id_token');
      this.setState({ loading: true });

      // Login request
      this.Auth.login(this.state.email, this.state.password)
        .then(res => {
          if (res.success) {
            this.Auth.site()
              .then(response => {
                this.setState({ loading: false });
                if (response.success) {
                  if (
                    res.userStatus === 'inactive' &&
                    response.data.site.systemType === 'ON_PREMISE'
                  ) {
                    this.setState({
                      systemType: response.data.site.systemType
                    });
                    localStorage.setItem(
                      'systemType',
                      response.data.site.systemType
                    );
                    this.props.history.push('/resetonprimiseuserpassword', {
                      email: this.state.email
                    });
                  } else {
                    this.props.history.push('/');
                  }
                } else {
                  this.props.alert.error(<I18nMessage id="Server Error" />);
                }
              })
              .catch(err => {
                this.setState({ loading: false });
                this.props.alert.error(<I18nMessage id="Server Error" />);
              });
          } else {
            this.setState({ loading: false });
            this.props.alert.error(res.message);
          }
        })
        .catch(err => {
          this.setState({ loading: false });
          this.props.alert.error(<I18nMessage id="Server Error" />);
        });
    } else {
      // Set Validation states.
      this.setState({ validation: validation });
    }
  }

  routeToForgotPassword(e) {
    e.preventDefault();
    this.props.history.push('/forgotpassword');
  }

  routeToResetPassword(e) {
    e.preventDefault();
    this.props.history.push('/resetonprimiseuserpassword', {
      email: this.state.email
    });
  }

  routeToRegister(e) {
    e.preventDefault();
    this.props.history.push('/register');
  }

  handleChange(e) {
    this.setState(
      {
        [e.target.name]: e.target.value
      },
      function() {
        const validation = this.validator.validate(this.state);
        this.setState({ validation: validation });
      }
    );
  }

  render() {
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <Form>
                      <FormGroup>
                        <h1>
                          <I18nMessage id="Login" />
                        </h1>
                        <p className="text-muted">
                          <I18nMessage id="Sign In to your account" />
                        </p>
                        <InputGroup className="mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="icon-user" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            valid={
                              !this.state.validation.email.isInvalid &&
                              this.state.validation.email.isTouched
                                ? !this.state.validation.email.isInvalid
                                : null
                            }
                            invalid={
                              this.state.validation.email.isInvalid
                                ? this.state.validation.email.isInvalid
                                : null
                            }
                            type="text"
                            placeholder="email"
                            onChange={this.handleChange.bind(this)}
                            name="email"
                            value={this.state.email}
                          />
                          {this.state.validation.email.isInvalid &&
                          this.state.validation.email.isTouched ? (
                            <FormFeedback invalid>
                              {this.state.validation.email.message}
                            </FormFeedback>
                          ) : (
                            <FormFeedback valid>
                              {this.state.validation.email.message}
                            </FormFeedback>
                          )}
                        </InputGroup>
                        <InputGroup className="mb-4">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="icon-lock" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            valid={
                              !this.state.validation.password.isInvalid &&
                              this.state.validation.password.isTouched
                                ? !this.state.validation.password.isInvalid
                                : null
                            }
                            invalid={
                              this.state.validation.password.isInvalid
                                ? this.state.validation.password.isInvalid
                                : null
                            }
                            type="password"
                            placeholder="Password"
                            name="password"
                            onChange={this.handleChange.bind(this)}
                            value={this.state.password}
                          />
                          {this.state.validation.password.isInvalid &&
                          this.state.validation.password.isTouched ? (
                            <FormFeedback invalid>
                              {this.state.validation.password.message}
                            </FormFeedback>
                          ) : (
                            <FormFeedback valid>
                              {this.state.validation.password.message}
                            </FormFeedback>
                          )}
                        </InputGroup>
                        <Row>
                          <Col xs="6">
                            <Button
                              color="primary"
                              className="px-4"
                              value="SUBMIT"
                              type="submit"
                              onClick={this.handleFormSubmit}
                              disabled={!this.state.validation.isValid}
                            >
                              <I18nMessage id="Login" />
                            </Button>
                          </Col>
                          {this.state.email ? (
                            <Col xs="6" className="text-right">
                              <Button
                                color="link"
                                className="px-0"
                                onClick={this.routeToResetPassword.bind(this)}
                              >
                                <I18nMessage id="Reset password" />
                              </Button>
                            </Col>
                          ) : null}
                        </Row>
                        <Row>
                          <Col
                            xs="12"
                            className="d-block d-sm-none d-none d-sm-block d-md-none justify-content-center p-10"
                          >
                            <Button
                              color="link"
                              className="px-0"
                              onClick={this.routeToRegister.bind(this)}
                            >
                              <I18nMessage id="New user Register" />
                            </Button>
                          </Col>
                        </Row>

                        <Loader loading={this.state.loading} />
                      </FormGroup>
                    </Form>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default withAlert(Login);
