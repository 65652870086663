import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, Nav } from 'reactstrap';
import PropTypes from 'prop-types';
import { withAlert } from 'react-alert';

import { Link } from 'react-router-dom';

import {
  AppHeaderDropdown,
  AppNavbarBrand,
  AppSidebarToggler
} from '@coreui/react';
import logo from '../../assets/img/brand/SmartMixTransparentWhite.png';
import logoRound from '../../assets/img/brand/mobileLogo.png';
import AuthService from '../AuthService/authentication';
import I18nMessage from '../i18n/I18nMessage';
import localStorage from 'localStorage';
import _ from 'lodash';

import {
  CONFIG_UI_LOCAL_STORAGE,
  CONFIG_UI_KEY
} from '../../utils/constantstrings';

// Redux and Localization
import SwitchLocale from '../SwitchLocale';

const propTypes = {
  children: PropTypes.node
};

const defaultProps = {};

class ProtectedHeader extends Component {
  constructor() {
    super();
    this.state = {
      dropdownOpen: [false, false],
      userEmail: '',
      role: ''
    };
    this.handleLogout = this.handleLogout.bind(this);
    this.Auth = new AuthService();
    this.checkSuperLogin = this.checkSuperLogin.bind(this);
    this.handleResetpassword = this.handleResetpassword.bind(this);
  }

  componentDidMount() {
    const userObject = this.Auth.getProfile();
    this.setState({
      userEmail: userObject.userEmail,
      role: userObject.role
    });
  }

  checkSuperLogin() {
    this.Auth.checkSuperUserLoginRequired()
      .then(res => {
        // Examine the text in the response
        if (res.success) {
          if (res.superUserLogin) {
            // Redirect to super user login.
            this.props.history.push('/superuserlogin');
          } else {
            // Redirect to normal login.
            this.props.history.push('/login');
          }
        } else {
          if (res.errors) {
            this.props.alert.error(res.errors[Object.keys(res.errors)[0]]);
          } else {
            this.props.alert.error(res.message);
          }
        }
      })
      .catch(err => {
        this.props.alert.error(
          'Something went wrong. Please contact administrator.'
        );
      });
  }

  toggle(i) {
    const newArray = this.state.dropdownOpen.map((element, index) => {
      return index === i ? !element : false;
    });
    this.setState({
      dropdownOpen: newArray
    });
  }

  handleLogout(e) {
    e.preventDefault();
    this.Auth.logout().then(() => {
      localStorage.removeItem('systemType');
      localStorage.removeItem('role');
      localStorage.removeItem('privileges');
      this.checkSuperLogin();
    });
  }

  handleResetpassword(e) {
    e.preventDefault();
    this.props.history.push('/resetonprimiseuserpassword', {
      email: this.state.userEmail
    });
  }

  handleRefreshConfigUI(e) {
    e.preventDefault();

    this.getAllLines();
  }

  getAllLines() {
    // Get all lines listed.
    this.Auth.lines()
      .then(res => {
        this.setState({ loading: false });
        if (res.success) {
          let lines = res.lines;
          let linesWithConfigUI = _.map(
            _.filter(lines, Obj => {
              return !_.isEmpty(Obj[CONFIG_UI_KEY]);
            }),
            Obj => _.pick(Obj, '_id', CONFIG_UI_KEY)
          );

          localStorage.setItem(
            CONFIG_UI_LOCAL_STORAGE,
            JSON.stringify(linesWithConfigUI)
          );
        }
        // Else Show Error
        else {
          this.props.alert.error(res.message);
        }
      })
      .catch(err => {
        this.setState({ loading: false });
        this.props.alert.error(<I18nMessage id="Error Getting Lines" />);
      });
  }

  render() {
    // eslint-disable-next-line
    const { children, ...attributes } = this.props;

    return (
      <React.Fragment>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        <AppNavbarBrand
          full={{ src: logo, width: 140, height: 19, alt: 'Smartmix Logo' }}
          minimized={{
            src: logoRound,
            width: 30,
            height: 30,
            alt: 'Smartmix Logo'
          }}
        />
        <Nav className="ml-auto" navbar>
          <SwitchLocale />
          <AppHeaderDropdown direction="down">
            <DropdownToggle nav>
              <img
                src={'/assets/img/avatars/userAvatar.png'}
                className="img-avatar"
                alt="User"
              />
            </DropdownToggle>
            <DropdownMenu right style={{ right: 'auto' }}>
              <DropdownItem header>{this.state.userEmail}</DropdownItem>
              {this.state.role === '_SUPER' ? (
                <DropdownItem onClick={this.handleRefreshConfigUI.bind(this)}>
                  <i class="fa fa-refresh" aria-hidden="true" />{' '}
                  <I18nMessage id="Refresh Config UI" />
                </DropdownItem>
              ) : (
                <DropdownItem onClick={this.handleResetpassword.bind(this)}>
                  <i className="icon-lock" />{' '}
                  <I18nMessage id="Reset Password" />
                </DropdownItem>
              )}

              <Link to={`/faq`}>
                <DropdownItem>
                  <i class="fa fa-question-circle" aria-hidden="true" />{' '}
                  <I18nMessage id="FAQ" />
                </DropdownItem>
              </Link>

              <DropdownItem onClick={this.handleLogout.bind(this)}>
                <i className="fa fa-sign-out" /> <I18nMessage id="Logout" />
              </DropdownItem>
            </DropdownMenu>
          </AppHeaderDropdown>
        </Nav>
      </React.Fragment>
    );
  }
}

ProtectedHeader.propTypes = propTypes;
ProtectedHeader.defaultProps = defaultProps;

export default withAlert(withRouter(ProtectedHeader));
